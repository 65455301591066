import React, { useState } from "react";
import { getYearLabel } from "../../../CommonFiles/Utils/ObjectUtils";
import "./EligibilityConfigForm.css";

const DefaultProgrammes = ["Full Time", "Part Time", "Distance Learning"];
const DefaultPassoutYears = Array.from(
  { length: 6 },
  (_, i) => new Date().getFullYear() + i
);

function EligibilityConfigPreview({ eligibilityConfig }) {
  const [eligibilityChoices, setEligibilityChoices] = useState({
    Occupation: "",
    Class: "",
    MajorSubjects: "",
    Experience: "",
    Industry: "",
    Company: "",
    Domain: "",
    Course: "",
    Specialization: "",
    Programme: "",
    CourseDuration: "",
    YearOfStudy: "",
    PassoutYear: "",
    OtherDomain: "",
    OtherCourse: "",
    OtherSpecialization: "",
  });

  const onOccupationChange = (newValue) => {
    setEligibilityChoices({
      Occupation: newValue,
      Class: "",
      MajorSubjects: "",
      Experience: "",
      Industry: "",
      Company: "",
      Domain: "",
      Course: "",
      Specialization: "",
      Programme: "",
      CourseDuration: "",
      YearOfStudy: "",
      PassoutYear: "",
      OtherDomain: "",
      OtherCourse: "",
      OtherSpecialization: "",
    });
  };

  return (
    <React.Fragment>
      <h6
        style={{
          fontWeight: "bold",
          marginLeft: "15px",
          position: "relative",
          top: "23px",
          backgroundColor: "#fff",
          padding: "0 10px",
        }}
      >
        Eligibility
      </h6>
      <div className="eligibility-form-container">
        <div className={`input-field`}>
          <div className="field-label-container">
            <label>Participant Type</label>
          </div>
          <select
            className="form-control"
            onChange={(e) => {
              onOccupationChange(e.target.value);
            }}
            value={eligibilityChoices.Occupation}
          >
            <option value="">--- Select ---</option>
            {eligibilityConfig.SchoolStudentsOptions && (
              <option value="School Student">School Student</option>
            )}
            {eligibilityConfig.CollegeStudentsOptions && (
              <option value="College Student">College Student</option>
            )}
            {eligibilityConfig.ProfessionalsOptions && (
              <option value="Professional">Professional</option>
            )}
          </select>
        </div>

        {/* School Student Options Starts */}
        {eligibilityConfig.SchoolStudentsOptions &&
          eligibilityChoices.Occupation === "School Student" && (
            <>
              <div className={`input-field`}>
                <div className="field-label-container">
                  <label>Class</label>
                </div>
                <select
                  className="form-control"
                  value={eligibilityChoices.Class}
                  onChange={(e) =>
                    setEligibilityChoices({
                      ...eligibilityChoices,
                      Class: e.target.value,
                    })
                  }
                >
                  <option value="">--- Select ---</option>
                  {eligibilityConfig.SchoolStudentsOptions.map(
                    (item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    )
                  )}
                </select>
              </div>

              <div className={`input-field`}>
                <div className="field-label-container">
                  <label>Major Subject(s)</label>
                </div>
                <input
                  type="text"
                  className="form-control"
                  value={eligibilityChoices.MajorSubjects}
                  placeholder="Enter Major Subject(s)"
                  onChange={(e) => {
                    setEligibilityChoices({
                      ...eligibilityChoices,
                      MajorSubjects: e.target.value,
                    });
                  }}
                />
              </div>
            </>
          )}
        {/* School Student Options Ends */}

        {/* College Student Options Starts */}
        {eligibilityConfig.CollegeStudentsOptions &&
          eligibilityChoices.Occupation === "College Student" && (
            <>
              <div className={`input-field`}>
                <div className="field-label-container">
                  <label>Domain</label>
                </div>
                <select
                  className="form-control"
                  value={eligibilityChoices.Domain}
                  onChange={(e) =>
                    setEligibilityChoices({
                      ...eligibilityChoices,
                      Domain: e.target.value,
                      Course: e.target.value === "Others" ? "Others" : "",
                      CourseDuration: "",
                      YearOfStudy: "",
                      Specialization:
                        e.target.value === "Others" ? "Others" : "",
                      Programme: "",
                      PassoutYear: "",
                      OtherDomain: "",
                      OtherCourse: "",
                      OtherSpecialization: "",
                    })
                  }
                >
                  <option value="">--- Select ---</option>
                  {eligibilityConfig.CollegeStudentsOptions.map(
                    (item, index) =>
                      item.courseNames.length > 0 && (
                        <option key={index} value={item.domain}>
                          {item.domain}
                        </option>
                      )
                  )}
                </select>
              </div>

              {eligibilityChoices.Domain !== "" &&
                eligibilityChoices.Domain !== "Others" &&
                eligibilityChoices.Domain !== "Other" && (
                  <>
                    <div className={`input-field`}>
                      <div className="field-label-container">
                        <label>Course</label>
                      </div>
                      <select
                        className="form-control"
                        value={eligibilityChoices.Course}
                        onChange={(e) => {
                          setEligibilityChoices({
                            ...eligibilityChoices,
                            Course: e.target.value,
                            CourseDuration: "",
                            YearOfStudy: "",
                            Specialization:
                              e.target.value === "Others" ? "Others" : "",
                            Programme: "",
                            PassoutYear: "",
                            OtherCourse: "",
                            OtherSpecialization: "",
                          });
                        }}
                      >
                        <option value="">--- Select ---</option>
                        {eligibilityConfig.CollegeStudentsOptions.map(
                          (item) =>
                            item.domain === eligibilityChoices.Domain &&
                            item.courseNames.map((course, index) => (
                              <option key={index} value={course.courseName}>
                                {course.courseName}
                              </option>
                            ))
                        )}
                      </select>
                    </div>

                    {eligibilityChoices.Course !== "" &&
                      eligibilityChoices.Course !== "Others" &&
                      eligibilityChoices.Course && (
                        <>
                          <div className={`input-field`}>
                            <div className="field-label-container">
                              <label>Program Type</label>
                            </div>
                            <select
                              className="form-control"
                              value={eligibilityChoices.Programme}
                              onChange={(e) => {
                                setEligibilityChoices({
                                  ...eligibilityChoices,
                                  Programme: e.target.value,
                                });
                              }}
                            >
                              <option value="">--- Select ---</option>
                              {eligibilityConfig.CollegeStudentsOptions.map(
                                (item) =>
                                  item.domain === eligibilityChoices.Domain &&
                                  item.courseNames.map(
                                    (course) =>
                                      course.courseName ===
                                        eligibilityChoices.Course &&
                                      course.programmeNames.map(
                                        (programmeName, index) => (
                                          <option
                                            key={index}
                                            value={programmeName}
                                          >
                                            {programmeName}
                                          </option>
                                        )
                                      )
                                  )
                              )}
                            </select>
                          </div>

                          <div className={`input-field`}>
                            <div className="field-label-container">
                              <label>Specialization</label>
                            </div>
                            <select
                              className="form-control"
                              value={eligibilityChoices.Specialization}
                              onChange={(e) => {
                                setEligibilityChoices({
                                  ...eligibilityChoices,
                                  Specialization: e.target.value,
                                  OtherSpecialization: "",
                                });
                              }}
                            >
                              <option value="">--- Select ---</option>
                              {eligibilityConfig.CollegeStudentsOptions.map(
                                (item) =>
                                  item.domain === eligibilityChoices.Domain &&
                                  item.courseNames.map(
                                    (course) =>
                                      course.courseName ===
                                        eligibilityChoices.Course &&
                                      course.specializationNames.map(
                                        (sp, index) => (
                                          <option
                                            key={index}
                                            value={sp.specializationName}
                                          >
                                            {sp.specializationName}
                                          </option>
                                        )
                                      )
                                  )
                              )}
                            </select>
                          </div>

                          {eligibilityChoices.Specialization === "Others" && (
                            <div className={`input-field`}>
                              <div className="field-label-container">
                                <label>Enter Specialization</label>
                              </div>
                              <input
                                type="text"
                                className="form-control"
                                value={eligibilityChoices.OtherSpecialization}
                                placeholder="Enter Specialization Name"
                                onChange={(e) => {
                                  setEligibilityChoices({
                                    ...eligibilityChoices,
                                    OtherSpecialization: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          )}

                          <div className={`input-field`}>
                            <div className="field-label-container">
                              <label>Course Duration</label>
                            </div>
                            <select
                              className="form-control"
                              value={eligibilityChoices.CourseDuration}
                              onChange={(e) => {
                                setEligibilityChoices({
                                  ...eligibilityChoices,
                                  CourseDuration: e.target.value,
                                });
                              }}
                            >
                              <option value="">--- Select ---</option>
                              {eligibilityConfig.CollegeStudentsOptions.map(
                                (item) =>
                                  item.domain === eligibilityChoices.Domain &&
                                  item.courseNames.map(
                                    (course) =>
                                      course.courseName ===
                                        eligibilityChoices.Course &&
                                      course.courseDurations
                                        .sort((a, b) => a - b)
                                        .map((duration, index) => (
                                          <option key={index} value={duration}>
                                            {`${Math.ceil(duration / 12)} Year${Math.ceil(duration / 12) > 1 ? "s" : ""}`}
                                          </option>
                                        ))
                                  )
                              )}
                            </select>
                          </div>

                          {eligibilityChoices.CourseDuration !== "" && (
                            <div className={`input-field`}>
                              <div className="field-label-container">
                                <label>Year of Study</label>
                              </div>
                              <select
                                className="form-control"
                                value={eligibilityChoices.YearOfStudy}
                                onChange={(e) => {
                                  setEligibilityChoices({
                                    ...eligibilityChoices,
                                    YearOfStudy: e.target.value,
                                  });
                                }}
                              >
                                <option value="">--- Select ---</option>
                                {eligibilityConfig.CollegeStudentsOptions.map(
                                  (item) =>
                                    item.domain === eligibilityChoices.Domain &&
                                    item.courseNames.map(
                                      (course) =>
                                        course.courseName ===
                                          eligibilityChoices.Course &&
                                        course.yearOfStudy
                                          .sort((a, b) => a - b)
                                          .filter(
                                            (item) =>
                                              item <=
                                              Math.ceil(
                                                Number(
                                                  eligibilityChoices.CourseDuration
                                                ) / 12
                                              )
                                          )
                                          .map((year, index) => (
                                            <option key={index} value={year}>
                                              {getYearLabel(year)}
                                            </option>
                                          ))
                                    )
                                )}
                              </select>
                            </div>
                          )}

                          <div className={`input-field`}>
                            <div className="field-label-container">
                              <label>Graduating Year</label>
                            </div>
                            <select
                              className="form-control"
                              value={eligibilityChoices.PassoutYear}
                              onChange={(e) => {
                                setEligibilityChoices({
                                  ...eligibilityChoices,
                                  PassoutYear: e.target.value,
                                });
                              }}
                            >
                              <option value="">--- Select ---</option>
                              {eligibilityConfig.CollegeStudentsOptions.map(
                                (item) =>
                                  item.domain === eligibilityChoices.Domain &&
                                  item.courseNames.map(
                                    (course) =>
                                      course.courseName ===
                                        eligibilityChoices.Course &&
                                      course.passoutYears.map(
                                        (passoutYear, index) => (
                                          <option
                                            key={index}
                                            value={passoutYear}
                                          >
                                            {passoutYear}
                                          </option>
                                        )
                                      )
                                  )
                              )}
                            </select>
                          </div>
                        </>
                      )}

                    {eligibilityChoices.Course === "Others" && (
                      <>
                        <div className={`input-field`}>
                          <div className="field-label-container">
                            <label>Enter Course</label>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            value={eligibilityChoices.OtherCourse}
                            placeholder="Enter Course Name"
                            onChange={(e) => {
                              setEligibilityChoices({
                                ...eligibilityChoices,
                                OtherCourse: e.target.value,
                              });
                            }}
                          />
                        </div>

                        <div className={`input-field`}>
                          <div className="field-label-container">
                            <label>Program Type</label>
                          </div>
                          <select
                            className="form-control"
                            value={eligibilityChoices.Programme}
                            onChange={(e) => {
                              setEligibilityChoices({
                                ...eligibilityChoices,
                                Programme: e.target.value,
                              });
                            }}
                          >
                            <option value="">--- Select ---</option>
                            {DefaultProgrammes.map((item, index) => (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className={`input-field`}>
                          <div className="field-label-container">
                            <label>Enter Specialization</label>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            value={eligibilityChoices.OtherSpecialization}
                            placeholder="Enter Specialization Name"
                            onChange={(e) => {
                              setEligibilityChoices({
                                ...eligibilityChoices,
                                OtherSpecialization: e.target.value,
                              });
                            }}
                          />
                        </div>

                        <div className={`input-field`}>
                          <div className="field-label-container">
                            <label>Enter Course Duration (in months)</label>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            value={eligibilityChoices.CourseDuration}
                            placeholder="Enter Course Duration"
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              // Allow only numbers
                              if (/^\d*$/.test(inputValue)) {
                                setEligibilityChoices({
                                  ...eligibilityChoices,
                                  CourseDuration: inputValue,
                                  YearOfStudy: "",
                                });
                              }
                            }}
                          />
                        </div>

                        {eligibilityChoices.CourseDuration !== "" && (
                          <div className={`input-field`}>
                            <div className="field-label-container">
                              <label>Year of Study</label>
                            </div>
                            <select
                              className="form-control"
                              value={eligibilityChoices.YearOfStudy}
                              onChange={(e) => {
                                setEligibilityChoices({
                                  ...eligibilityChoices,
                                  YearOfStudy: e.target.value,
                                });
                              }}
                            >
                              <option value="">--- Select ---</option>
                              {eligibilityConfig.CollegeStudentsOptions.map(
                                (item) =>
                                  item.domain === eligibilityChoices.Domain &&
                                  item.courseNames.map(
                                    (course) =>
                                      course.courseName ===
                                        eligibilityChoices.Course &&
                                      Array.from(
                                        {
                                          length: Math.ceil(
                                            Number(
                                              eligibilityChoices.CourseDuration
                                            ) / 12
                                          ),
                                        },
                                        (_, i) => i + 1
                                      ).map((year, index) => (
                                        <option key={index} value={year}>
                                          {getYearLabel(year)}
                                        </option>
                                      ))
                                  )
                              )}
                            </select>
                          </div>
                        )}

                        <div className={`input-field`}>
                          <div className="field-label-container">
                            <label>Graduating Year</label>
                          </div>
                          <select
                            className="form-control"
                            value={eligibilityChoices.PassoutYear}
                            onChange={(e) => {
                              setEligibilityChoices({
                                ...eligibilityChoices,
                                PassoutYear: e.target.value,
                              });
                            }}
                          >
                            <option value="">--- Select ---</option>
                            {DefaultPassoutYears.map((item, index) => (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            ))}
                          </select>
                        </div>
                      </>
                    )}
                  </>
                )}

              {eligibilityChoices.Domain === "Others" && (
                <>
                  <div className={`input-field`}>
                    <div className="field-label-container">
                      <label>Enter Domain Name</label>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      value={eligibilityChoices.OtherDomain}
                      placeholder="Enter Domain Name"
                      onChange={(e) => {
                        setEligibilityChoices({
                          ...eligibilityChoices,
                          OtherDomain: e.target.value,
                        });
                      }}
                    />
                  </div>

                  <div className={`input-field`}>
                    <div className="field-label-container">
                      <label>Enter Course</label>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      value={eligibilityChoices.OtherCourse}
                      placeholder="Enter Course Name"
                      onChange={(e) => {
                        setEligibilityChoices({
                          ...eligibilityChoices,
                          OtherCourse: e.target.value,
                        });
                      }}
                    />
                  </div>

                  <div className={`input-field`}>
                    <div className="field-label-container">
                      <label>Program Type</label>
                    </div>
                    <select
                      className="form-control"
                      value={eligibilityChoices.Programme}
                      onChange={(e) => {
                        setEligibilityChoices({
                          ...eligibilityChoices,
                          Programme: e.target.value,
                        });
                      }}
                    >
                      <option value="">--- Select ---</option>
                      {DefaultProgrammes.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className={`input-field`}>
                    <div className="field-label-container">
                      <label>Enter Specialization</label>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      value={eligibilityChoices.OtherSpecialization}
                      placeholder="Enter Specialization Name"
                      onChange={(e) => {
                        setEligibilityChoices({
                          ...eligibilityChoices,
                          OtherSpecialization: e.target.value,
                        });
                      }}
                    />
                  </div>

                  <div className={`input-field`}>
                    <div className="field-label-container">
                      <label>Enter Course Duration (in months)</label>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      value={eligibilityChoices.CourseDuration}
                      placeholder="Enter Course Duration"
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        // Allow only numbers
                        if (/^\d*$/.test(inputValue)) {
                          setEligibilityChoices({
                            ...eligibilityChoices,
                            CourseDuration: inputValue,
                            YearOfStudy: "",
                          });
                        }
                      }}
                    />
                  </div>

                  {eligibilityChoices.CourseDuration !== "" && (
                    <div className={`input-field`}>
                      <div className="field-label-container">
                        <label>Year of Study</label>
                      </div>
                      <select
                        className="form-control"
                        value={eligibilityChoices.YearOfStudy}
                        onChange={(e) => {
                          setEligibilityChoices({
                            ...eligibilityChoices,
                            YearOfStudy: e.target.value,
                          });
                        }}
                      >
                        <option value="">--- Select ---</option>
                        {eligibilityConfig.CollegeStudentsOptions.map(
                          (item) =>
                            item.domain === eligibilityChoices.Domain &&
                            Array.from(
                              {
                                length: Math.ceil(
                                  Number(eligibilityChoices.CourseDuration) / 12
                                ),
                              },
                              (_, i) => i + 1
                            ).map((year, index) => (
                              <option key={index} value={year}>
                                {getYearLabel(year)}
                              </option>
                            ))
                        )}
                      </select>
                    </div>
                  )}

                  <div className={`input-field`}>
                    <div className="field-label-container">
                      <label>Graduating Year</label>
                    </div>
                    <select
                      className="form-control"
                      value={eligibilityChoices.PassoutYear}
                      onChange={(e) => {
                        setEligibilityChoices({
                          ...eligibilityChoices,
                          PassoutYear: e.target.value,
                        });
                      }}
                    >
                      <option value="">--- Select ---</option>
                      {DefaultPassoutYears.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                </>
              )}
            </>
          )}

        {/* College Student Options Ends */}

        {/* Professional Options Starts */}
        {eligibilityConfig.ProfessionalsOptions &&
          eligibilityChoices.Occupation === "Professional" && (
            <>
              <div className={`input-field`}>
                <div className="field-label-container">
                  <label>Select Experience</label>
                </div>
                <select
                  className="form-control"
                  value={eligibilityChoices.Experience}
                  onChange={(e) => {
                    setEligibilityChoices({
                      ...eligibilityChoices,
                      Experience: e.target.value,
                    });
                  }}
                >
                  <option value="">--- Select ---</option>

                  {!eligibilityConfig.ProfessionalsOptions.isOpenToAll &&
                    Array.from(
                      {
                        length:
                          eligibilityConfig.ProfessionalsOptions.experienceMax -
                          eligibilityConfig.ProfessionalsOptions.experienceMin +
                          1,
                      },
                      (_, i) =>
                        eligibilityConfig.ProfessionalsOptions.experienceMin + i
                    ).map((item, index) => (
                      <option
                        key={index}
                        value={`${item} ${Number(item) > 1 ? " years" : " year"}`}
                      >
                        {`${item} ${Number(item) > 1 ? " years" : " year"}`}
                      </option>
                    ))}

                  {eligibilityConfig.ProfessionalsOptions.isOpenToAll && (
                    <>
                      <option value="< 12 months">&lt; 12 months</option>
                      {Array.from(
                        {
                          length: 9,
                        },
                        (_, i) => i + 1
                      ).map((item, index) => (
                        <option
                          key={index}
                          value={`${item} ${Number(item) > 1 ? " years" : " year"}`}
                        >
                          {`${item} ${Number(item) > 1 ? " years" : " year"}`}
                        </option>
                      ))}
                      <option value="> 10 years">&gt; 10 years</option>
                    </>
                  )}
                </select>
              </div>

              <div className={`input-field`}>
                <div className="field-label-container">
                  <label>Industry</label>
                </div>
                <input
                  type="text"
                  className="form-control"
                  value={eligibilityChoices.Industry}
                  placeholder="Enter Industry Name"
                  onChange={(e) => {
                    setEligibilityChoices({
                      ...eligibilityChoices,
                      Industry: e.target.value,
                    });
                  }}
                />
              </div>

              <div className={`input-field`}>
                <div className="field-label-container">
                  <label>Company</label>
                </div>
                <input
                  type="text"
                  className="form-control"
                  value={eligibilityChoices.Company}
                  placeholder="Enter Company Name"
                  onChange={(e) => {
                    setEligibilityChoices({
                      ...eligibilityChoices,
                      Company: e.target.value,
                    });
                  }}
                />
              </div>
            </>
          )}
        {/* Professional Options Ends */}
      </div>
    </React.Fragment>
  );
}

export default EligibilityConfigPreview;
