import React, { useEffect, useState } from "react";
import { getYearLabel, isEqual } from "../CommonFiles/Utils/ObjectUtils";

const DefaultProgrammes = ["Full Time", "Part Time", "Distance Learning"];
const DefaultPassoutYears = Array.from(
  { length: 6 },
  (_, i) => new Date().getFullYear() + i
);

function EligibilityChoicesUserForm({
  eligibilityConfig,
  editingConfig,
  onChange,
  errors,
  memberIndex,
}) {
  const dynamicFieldName = `eligibility_info_${memberIndex}`;
  const [eligibilityChoices, setEligibilityChoices] = useState(
    editingConfig ?? {
      Occupation: "",
      Class: "",
      MajorSubjects: "",
      Experience: "",
      Industry: "",
      Company: "",
      Domain: "",
      Course: "",
      Specialization: "",
      Programme: "",
      CourseDuration: "",
      YearOfStudy: "",
      PassoutYear: "",
      OtherDomain: "",
      OtherCourse: "",
      OtherSpecialization: "",
    }
  );

  useEffect(() => {
    editingConfig && setEligibilityChoices(editingConfig);
  }, [editingConfig]);

  useEffect(() => {
    if (isEqual(eligibilityChoices, editingConfig)) return;
    onChange(eligibilityChoices);
    // eslint-disable-next-line
  }, [eligibilityChoices]);

  const onOccupationChange = (newValue) => {
    setEligibilityChoices({
      Occupation: newValue,
      Class: "",
      MajorSubjects: "",
      Experience: "",
      Industry: "",
      Company: "",
      Domain: "",
      Course: "",
      Specialization: "",
      Programme: "",
      CourseDuration: "",
      YearOfStudy: "",
      PassoutYear: "",
      OtherDomain: "",
      OtherCourse: "",
      OtherSpecialization: "",
    });
  };

  return (
    <>
      <div className={`input-field`}>
        <div className="field-label-container">
          <label>
            Participant Type<span className="required-mark"> *</span>
          </label>
        </div>
        <select
          className="form-control"
          onChange={(e) => {
            onOccupationChange(e.target.value);
          }}
          value={eligibilityChoices.Occupation}
          disabled={memberIndex > 1}
        >
          <option value="">--- Select ---</option>
          {eligibilityConfig.SchoolStudentsOptions && (
            <option value="School Student">School Student</option>
          )}
          {eligibilityConfig.CollegeStudentsOptions && (
            <option value="College Student">College Student</option>
          )}
          {eligibilityConfig.ProfessionalsOptions && (
            <option value="Professional">Professional</option>
          )}
        </select>
        {errors[`${dynamicFieldName}.Occupation`] && (
          <span className="required-mark">
            {errors[`${dynamicFieldName}.Occupation`]}
          </span>
        )}
      </div>

      {/* School Student Options Starts */}
      {eligibilityConfig.SchoolStudentsOptions &&
        eligibilityChoices.Occupation === "School Student" && (
          <>
            <div className={`input-field`}>
              <div className="field-label-container">
                <label>
                  Class<span className="required-mark"> *</span>
                </label>
              </div>
              <select
                className="form-control"
                value={eligibilityChoices.Class}
                onChange={(e) =>
                  setEligibilityChoices({
                    ...eligibilityChoices,
                    Class: e.target.value,
                  })
                }
              >
                <option value="">--- Select ---</option>
                {eligibilityConfig.SchoolStudentsOptions.map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </select>
              {errors[`${dynamicFieldName}.Class`] && (
                <span className="required-mark">
                  {errors[`${dynamicFieldName}.Class`]}
                </span>
              )}
            </div>

            <div className={`input-field`}>
              <div className="field-label-container">
                <label>
                  Major Subject(s)<span className="required-mark"> *</span>
                </label>
              </div>
              <input
                type="text"
                className="form-control"
                value={eligibilityChoices.MajorSubjects}
                placeholder="Enter Major Subject(s)"
                onChange={(e) => {
                  setEligibilityChoices({
                    ...eligibilityChoices,
                    MajorSubjects: e.target.value,
                  });
                }}
              />
              {errors[`${dynamicFieldName}.MajorSubjects`] && (
                <span className="required-mark">
                  {errors[`${dynamicFieldName}.MajorSubjects`]}
                </span>
              )}
            </div>
          </>
        )}
      {/* School Student Options Ends */}

      {/* College Student Options Starts */}
      {eligibilityConfig.CollegeStudentsOptions &&
        eligibilityChoices.Occupation === "College Student" && (
          <>
            <div className={`input-field`}>
              <div className="field-label-container">
                <label>
                  Domain<span className="required-mark"> *</span>
                </label>
              </div>
              <select
                className="form-control"
                value={eligibilityChoices.Domain}
                onChange={(e) =>
                  setEligibilityChoices({
                    ...eligibilityChoices,
                    Domain: e.target.value,
                    Course: e.target.value === "Others" ? "Others" : "",
                    CourseDuration: "",
                    YearOfStudy: "",
                    Specialization: e.target.value === "Others" ? "Others" : "",
                    Programme: "",
                    PassoutYear: "",
                    OtherDomain: "",
                    OtherCourse: "",
                    OtherSpecialization: "",
                  })
                }
              >
                <option value="">--- Select ---</option>
                {eligibilityConfig.CollegeStudentsOptions.map(
                  (item, index) =>
                    item.courseNames.length > 0 && (
                      <option key={index} value={item.domain}>
                        {item.domain}
                      </option>
                    )
                )}
              </select>
              {errors[`${dynamicFieldName}.Domain`] && (
                <span className="required-mark">
                  {errors[`${dynamicFieldName}.Domain`]}
                </span>
              )}
            </div>

            {eligibilityChoices.Domain !== "" &&
              eligibilityChoices.Domain !== "Others" &&
              eligibilityChoices.Domain !== "Other" && (
                <>
                  <div className={`input-field`}>
                    <div className="field-label-container">
                      <label>
                        Course<span className="required-mark"> *</span>
                      </label>
                    </div>
                    <select
                      className="form-control"
                      value={eligibilityChoices.Course}
                      onChange={(e) => {
                        setEligibilityChoices({
                          ...eligibilityChoices,
                          Course: e.target.value,
                          CourseDuration: "",
                          YearOfStudy: "",
                          Specialization:
                            e.target.value === "Others" ? "Others" : "",
                          Programme: "",
                          PassoutYear: "",
                          OtherCourse: "",
                          OtherSpecialization: "",
                        });
                      }}
                    >
                      <option value="">--- Select ---</option>
                      {eligibilityConfig.CollegeStudentsOptions.map(
                        (item) =>
                          item.domain === eligibilityChoices.Domain &&
                          item.courseNames.map((course, index) => (
                            <option key={index} value={course.courseName}>
                              {course.courseName}
                            </option>
                          ))
                      )}
                    </select>
                    {errors[`${dynamicFieldName}.Course`] && (
                      <span className="required-mark">
                        {errors[`${dynamicFieldName}.Course`]}
                      </span>
                    )}
                  </div>

                  {eligibilityChoices.Course !== "" &&
                    eligibilityChoices.Course !== "Others" &&
                    eligibilityChoices.Course && (
                      <>
                        <div className={`input-field`}>
                          <div className="field-label-container">
                            <label>
                              Program Type
                              <span className="required-mark"> *</span>
                            </label>
                          </div>
                          <select
                            className="form-control"
                            value={eligibilityChoices.Programme}
                            onChange={(e) => {
                              setEligibilityChoices({
                                ...eligibilityChoices,
                                Programme: e.target.value,
                              });
                            }}
                          >
                            <option value="">--- Select ---</option>
                            {eligibilityConfig.CollegeStudentsOptions.map(
                              (item) =>
                                item.domain === eligibilityChoices.Domain &&
                                item.courseNames.map(
                                  (course) =>
                                    course.courseName ===
                                      eligibilityChoices.Course &&
                                    course.programmeNames.map(
                                      (programmeName, index) => (
                                        <option
                                          key={index}
                                          value={programmeName}
                                        >
                                          {programmeName}
                                        </option>
                                      )
                                    )
                                )
                            )}
                          </select>
                          {errors[`${dynamicFieldName}.Programme`] && (
                            <span className="required-mark">
                              {errors[`${dynamicFieldName}.Programme`]}
                            </span>
                          )}
                        </div>

                        <div className={`input-field`}>
                          <div className="field-label-container">
                            <label>
                              Specialization
                              <span className="required-mark"> *</span>
                            </label>
                          </div>
                          <select
                            className="form-control"
                            value={eligibilityChoices.Specialization}
                            onChange={(e) => {
                              setEligibilityChoices({
                                ...eligibilityChoices,
                                Specialization: e.target.value,
                                OtherSpecialization: "",
                              });
                            }}
                          >
                            <option value="">--- Select ---</option>
                            {eligibilityConfig.CollegeStudentsOptions.map(
                              (item) =>
                                item.domain === eligibilityChoices.Domain &&
                                item.courseNames.map(
                                  (course) =>
                                    course.courseName ===
                                      eligibilityChoices.Course &&
                                    course.specializationNames.map(
                                      (sp, index) => (
                                        <option
                                          key={index}
                                          value={sp.specializationName}
                                        >
                                          {sp.specializationName}
                                        </option>
                                      )
                                    )
                                )
                            )}
                          </select>
                          {errors[`${dynamicFieldName}.Specialization`] && (
                            <span className="required-mark">
                              {errors[`${dynamicFieldName}.Specialization`]}
                            </span>
                          )}
                        </div>

                        {eligibilityChoices.Specialization === "Others" && (
                          <div className={`input-field`}>
                            <div className="field-label-container">
                              <label>
                                Enter Specialization
                                <span className="required-mark"> *</span>
                              </label>
                            </div>
                            <input
                              type="text"
                              className="form-control"
                              value={eligibilityChoices.OtherSpecialization}
                              placeholder="Enter Specialization Name"
                              onChange={(e) => {
                                setEligibilityChoices({
                                  ...eligibilityChoices,
                                  OtherSpecialization: e.target.value,
                                });
                              }}
                            />
                            {errors[
                              `${dynamicFieldName}.OtherSpecialization`
                            ] && (
                              <span className="required-mark">
                                {
                                  errors[
                                    `${dynamicFieldName}.OtherSpecialization`
                                  ]
                                }
                              </span>
                            )}
                          </div>
                        )}

                        <div className={`input-field`}>
                          <div className="field-label-container">
                            <label>
                              Course Duration
                              <span className="required-mark"> *</span>
                            </label>
                          </div>
                          <select
                            className="form-control"
                            value={eligibilityChoices.CourseDuration}
                            onChange={(e) => {
                              setEligibilityChoices({
                                ...eligibilityChoices,
                                CourseDuration: e.target.value,
                              });
                            }}
                          >
                            <option value="">--- Select ---</option>
                            {eligibilityConfig.CollegeStudentsOptions.map(
                              (item) =>
                                item.domain === eligibilityChoices.Domain &&
                                item.courseNames.map(
                                  (course) =>
                                    course.courseName ===
                                      eligibilityChoices.Course &&
                                    course.courseDurations
                                      .sort((a, b) => a - b)
                                      .map((duration, index) => (
                                        <option key={index} value={duration}>
                                          {`${Math.ceil(duration / 12)} Year${Math.ceil(duration / 12) > 1 ? "s" : ""}`}
                                        </option>
                                      ))
                                )
                            )}
                          </select>
                          {errors[`${dynamicFieldName}.CourseDuration`] && (
                            <span className="required-mark">
                              {errors[`${dynamicFieldName}.CourseDuration`]}
                            </span>
                          )}
                        </div>

                        {eligibilityChoices.CourseDuration !== "" && (
                          <div className={`input-field`}>
                            <div className="field-label-container">
                              <label>
                                Year of Study
                                <span className="required-mark"> *</span>
                              </label>
                            </div>
                            <select
                              className="form-control"
                              value={eligibilityChoices.YearOfStudy}
                              onChange={(e) => {
                                setEligibilityChoices({
                                  ...eligibilityChoices,
                                  YearOfStudy: e.target.value,
                                });
                              }}
                            >
                              <option value="">--- Select ---</option>
                              {eligibilityConfig.CollegeStudentsOptions.map(
                                (item) =>
                                  item.domain === eligibilityChoices.Domain &&
                                  item.courseNames.map(
                                    (course) =>
                                      course.courseName ===
                                        eligibilityChoices.Course &&
                                      course.yearOfStudy
                                        .sort((a, b) => a - b)
                                        .filter(
                                          (item) =>
                                            item <=
                                            Math.ceil(
                                              Number(
                                                eligibilityChoices.CourseDuration
                                              ) / 12
                                            )
                                        )
                                        .map((year, index) => (
                                          <option key={index} value={year}>
                                            {getYearLabel(year)}
                                          </option>
                                        ))
                                  )
                              )}
                            </select>
                            {errors[`${dynamicFieldName}.YearOfStudy`] && (
                              <span className="required-mark">
                                {errors[`${dynamicFieldName}.YearOfStudy`]}
                              </span>
                            )}
                          </div>
                        )}

                        <div className={`input-field`}>
                          <div className="field-label-container">
                            <label>
                              Graduating Year
                              <span className="required-mark"> *</span>
                            </label>
                          </div>
                          <select
                            className="form-control"
                            value={eligibilityChoices.PassoutYear}
                            onChange={(e) => {
                              setEligibilityChoices({
                                ...eligibilityChoices,
                                PassoutYear: e.target.value,
                              });
                            }}
                          >
                            <option value="">--- Select ---</option>
                            {eligibilityConfig.CollegeStudentsOptions.map(
                              (item) =>
                                item.domain === eligibilityChoices.Domain &&
                                item.courseNames.map(
                                  (course) =>
                                    course.courseName ===
                                      eligibilityChoices.Course &&
                                    course.passoutYears.map(
                                      (passoutYear, index) => (
                                        <option key={index} value={passoutYear}>
                                          {passoutYear}
                                        </option>
                                      )
                                    )
                                )
                            )}
                          </select>
                          {errors[`${dynamicFieldName}.PassoutYear`] && (
                            <span className="required-mark">
                              {errors[`${dynamicFieldName}.PassoutYear`]}
                            </span>
                          )}
                        </div>
                      </>
                    )}

                  {eligibilityChoices.Course === "Others" && (
                    <>
                      <div className={`input-field`}>
                        <div className="field-label-container">
                          <label>
                            Enter Course
                            <span className="required-mark"> *</span>
                          </label>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          value={eligibilityChoices.OtherCourse}
                          placeholder="Enter Course Name"
                          onChange={(e) => {
                            setEligibilityChoices({
                              ...eligibilityChoices,
                              OtherCourse: e.target.value,
                            });
                          }}
                        />
                        {errors[`${dynamicFieldName}.OtherCourse`] && (
                          <span className="required-mark">
                            {errors[`${dynamicFieldName}.OtherCourse`]}
                          </span>
                        )}
                      </div>

                      <div className={`input-field`}>
                        <div className="field-label-container">
                          <label>
                            Program Type
                            <span className="required-mark"> *</span>
                          </label>
                        </div>
                        <select
                          className="form-control"
                          value={eligibilityChoices.Programme}
                          onChange={(e) => {
                            setEligibilityChoices({
                              ...eligibilityChoices,
                              Programme: e.target.value,
                            });
                          }}
                        >
                          <option value="">--- Select ---</option>
                          {DefaultProgrammes.map((item, index) => (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          ))}
                        </select>
                        {errors[`${dynamicFieldName}.Programme`] && (
                          <span className="required-mark">
                            {errors[`${dynamicFieldName}.Programme`]}
                          </span>
                        )}
                      </div>

                      <div className={`input-field`}>
                        <div className="field-label-container">
                          <label>
                            Enter Specialization
                            <span className="required-mark"> *</span>
                          </label>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          value={eligibilityChoices.OtherSpecialization}
                          placeholder="Enter Specialization Name"
                          onChange={(e) => {
                            setEligibilityChoices({
                              ...eligibilityChoices,
                              OtherSpecialization: e.target.value,
                            });
                          }}
                        />
                        {errors[`${dynamicFieldName}.OtherSpecialization`] && (
                          <span className="required-mark">
                            {errors[`${dynamicFieldName}.OtherSpecialization`]}
                          </span>
                        )}
                      </div>

                      <div className={`input-field`}>
                        <div className="field-label-container">
                          <label>
                            Enter Course Duration (in months)
                            <span className="required-mark"> *</span>
                          </label>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          value={eligibilityChoices.CourseDuration}
                          placeholder="Enter Course Duration"
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            // Allow only numbers
                            if (/^\d*$/.test(inputValue)) {
                              setEligibilityChoices({
                                ...eligibilityChoices,
                                CourseDuration: inputValue,
                                YearOfStudy: "",
                              });
                            }
                          }}
                        />
                        {errors[`${dynamicFieldName}.CourseDuration`] && (
                          <span className="required-mark">
                            {errors[`${dynamicFieldName}.CourseDuration`]}
                          </span>
                        )}
                      </div>

                      {eligibilityChoices.CourseDuration !== "" && (
                        <div className={`input-field`}>
                          <div className="field-label-container">
                            <label>
                              Year of Study
                              <span className="required-mark"> *</span>
                            </label>
                          </div>
                          <select
                            className="form-control"
                            value={eligibilityChoices.YearOfStudy}
                            onChange={(e) => {
                              setEligibilityChoices({
                                ...eligibilityChoices,
                                YearOfStudy: e.target.value,
                              });
                            }}
                          >
                            <option value="">--- Select ---</option>
                            {eligibilityConfig.CollegeStudentsOptions.map(
                              (item) =>
                                item.domain === eligibilityChoices.Domain &&
                                item.courseNames.map(
                                  (course) =>
                                    course.courseName ===
                                      eligibilityChoices.Course &&
                                    Array.from(
                                      {
                                        length: Math.ceil(
                                          Number(
                                            eligibilityChoices.CourseDuration
                                          ) / 12
                                        ),
                                      },
                                      (_, i) => i + 1
                                    ).map((year, index) => (
                                      <option key={index} value={year}>
                                        {getYearLabel(year)}
                                      </option>
                                    ))
                                )
                            )}
                          </select>
                          {errors[`${dynamicFieldName}.YearOfStudy`] && (
                            <span className="required-mark">
                              {errors[`${dynamicFieldName}.YearOfStudy`]}
                            </span>
                          )}
                        </div>
                      )}

                      <div className={`input-field`}>
                        <div className="field-label-container">
                          <label>
                            Graduating Year
                            <span className="required-mark"> *</span>
                          </label>
                        </div>
                        <select
                          className="form-control"
                          value={eligibilityChoices.PassoutYear}
                          onChange={(e) => {
                            setEligibilityChoices({
                              ...eligibilityChoices,
                              PassoutYear: e.target.value,
                            });
                          }}
                        >
                          <option value="">--- Select ---</option>
                          {DefaultPassoutYears.map((item, index) => (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          ))}
                        </select>
                        {errors[`${dynamicFieldName}.PassoutYear`] && (
                          <span className="required-mark">
                            {errors[`${dynamicFieldName}.PassoutYear`]}
                          </span>
                        )}
                      </div>
                    </>
                  )}
                </>
              )}

            {eligibilityChoices.Domain === "Others" && (
              <>
                <div className={`input-field`}>
                  <div className="field-label-container">
                    <label>
                      Enter Domain Name<span className="required-mark"> *</span>
                    </label>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    value={eligibilityChoices.OtherDomain}
                    placeholder="Enter Domain Name"
                    onChange={(e) => {
                      setEligibilityChoices({
                        ...eligibilityChoices,
                        OtherDomain: e.target.value,
                      });
                    }}
                  />
                  {errors[`${dynamicFieldName}.OtherDomain`] && (
                    <span className="required-mark">
                      {errors[`${dynamicFieldName}.OtherDomain`]}
                    </span>
                  )}
                </div>

                <div className={`input-field`}>
                  <div className="field-label-container">
                    <label>
                      Enter Course<span className="required-mark"> *</span>
                    </label>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    value={eligibilityChoices.OtherCourse}
                    placeholder="Enter Course Name"
                    onChange={(e) => {
                      setEligibilityChoices({
                        ...eligibilityChoices,
                        OtherCourse: e.target.value,
                      });
                    }}
                  />
                  {errors[`${dynamicFieldName}.OtherCourse`] && (
                    <span className="required-mark">
                      {errors[`${dynamicFieldName}.OtherCourse`]}
                    </span>
                  )}
                </div>

                <div className={`input-field`}>
                  <div className="field-label-container">
                    <label>
                      Program Type<span className="required-mark"> *</span>
                    </label>
                  </div>
                  <select
                    className="form-control"
                    value={eligibilityChoices.Programme}
                    onChange={(e) => {
                      setEligibilityChoices({
                        ...eligibilityChoices,
                        Programme: e.target.value,
                      });
                    }}
                  >
                    <option value="">--- Select ---</option>
                    {DefaultProgrammes.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                  {errors[`${dynamicFieldName}.Programme`] && (
                    <span className="required-mark">
                      {errors[`${dynamicFieldName}.Programme`]}
                    </span>
                  )}
                </div>

                <div className={`input-field`}>
                  <div className="field-label-container">
                    <label>
                      Enter Specialization
                      <span className="required-mark"> *</span>
                    </label>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    value={eligibilityChoices.OtherSpecialization}
                    placeholder="Enter Specialization Name"
                    onChange={(e) => {
                      setEligibilityChoices({
                        ...eligibilityChoices,
                        OtherSpecialization: e.target.value,
                      });
                    }}
                  />
                  {errors[`${dynamicFieldName}.OtherSpecialization`] && (
                    <span className="required-mark">
                      {errors[`${dynamicFieldName}.OtherSpecialization`]}
                    </span>
                  )}
                </div>

                <div className={`input-field`}>
                  <div className="field-label-container">
                    <label>
                      Enter Course Duration (in months)
                      <span className="required-mark"> *</span>
                    </label>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    value={eligibilityChoices.CourseDuration}
                    placeholder="Enter Course Duration"
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      // Allow only numbers
                      if (/^\d*$/.test(inputValue)) {
                        setEligibilityChoices({
                          ...eligibilityChoices,
                          CourseDuration: inputValue,
                          YearOfStudy: "",
                        });
                      }
                    }}
                  />
                  {errors[`${dynamicFieldName}.CourseDuration`] && (
                    <span className="required-mark">
                      {errors[`${dynamicFieldName}.CourseDuration`]}
                    </span>
                  )}
                </div>

                {eligibilityChoices.CourseDuration !== "" && (
                  <div className={`input-field`}>
                    <div className="field-label-container">
                      <label>
                        Year of Study<span className="required-mark"> *</span>
                      </label>
                    </div>
                    <select
                      className="form-control"
                      value={eligibilityChoices.YearOfStudy}
                      onChange={(e) => {
                        setEligibilityChoices({
                          ...eligibilityChoices,
                          YearOfStudy: e.target.value,
                        });
                      }}
                    >
                      <option value="">--- Select ---</option>
                      {eligibilityConfig.CollegeStudentsOptions.map(
                        (item) =>
                          item.domain === eligibilityChoices.Domain &&
                          Array.from(
                            {
                              length: Math.ceil(
                                Number(eligibilityChoices.CourseDuration) / 12
                              ),
                            },
                            (_, i) => i + 1
                          ).map((year, index) => (
                            <option key={index} value={year}>
                              {getYearLabel(year)}
                            </option>
                          ))
                      )}
                    </select>
                    {errors[`${dynamicFieldName}.YearOfStudy`] && (
                      <span className="required-mark">
                        {errors[`${dynamicFieldName}.YearOfStudy`]}
                      </span>
                    )}
                  </div>
                )}

                <div className={`input-field`}>
                  <div className="field-label-container">
                    <label>
                      Graduating Year
                      <span className="required-mark"> *</span>
                    </label>
                  </div>
                  <select
                    className="form-control"
                    value={eligibilityChoices.PassoutYear}
                    onChange={(e) => {
                      setEligibilityChoices({
                        ...eligibilityChoices,
                        PassoutYear: e.target.value,
                      });
                    }}
                  >
                    <option value="">--- Select ---</option>
                    {DefaultPassoutYears.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                  {errors[`${dynamicFieldName}.PassoutYear`] && (
                    <span className="required-mark">
                      {errors[`${dynamicFieldName}.PassoutYear`]}
                    </span>
                  )}
                </div>
              </>
            )}
          </>
        )}

      {/* College Student Options Ends */}

      {/* Professional Options Starts */}
      {eligibilityConfig.ProfessionalsOptions &&
        eligibilityChoices.Occupation === "Professional" && (
          <>
            <div className={`input-field`}>
              <div className="field-label-container">
                <label>
                  Select Experience<span className="required-mark"> *</span>
                </label>
              </div>
              <select
                className="form-control"
                value={eligibilityChoices.Experience}
                onChange={(e) => {
                  setEligibilityChoices({
                    ...eligibilityChoices,
                    Experience: e.target.value,
                  });
                }}
              >
                <option value="">--- Select ---</option>

                {!eligibilityConfig.ProfessionalsOptions.isOpenToAll &&
                  Array.from(
                    {
                      length:
                        eligibilityConfig.ProfessionalsOptions.experienceMax -
                        eligibilityConfig.ProfessionalsOptions.experienceMin +
                        1,
                    },
                    (_, i) =>
                      eligibilityConfig.ProfessionalsOptions.experienceMin + i
                  ).map((item, index) => (
                    <option
                      key={index}
                      value={`${item} ${Number(item) > 1 ? " years" : " year"}`}
                    >
                      {`${item} ${Number(item) > 1 ? " years" : " year"}`}
                    </option>
                  ))}

                {eligibilityConfig.ProfessionalsOptions.isOpenToAll && (
                  <>
                    <option value="< 12 months">&lt; 12 months</option>
                    {Array.from(
                      {
                        length: 9,
                      },
                      (_, i) => i + 1
                    ).map((item, index) => (
                      <option
                        key={index}
                        value={`${item} ${Number(item) > 1 ? " years" : " year"}`}
                      >
                        {`${item} ${Number(item) > 1 ? " years" : " year"}`}
                      </option>
                    ))}
                    <option value="> 10 years">&gt; 10 years</option>
                  </>
                )}
              </select>
              {errors[`${dynamicFieldName}.Experience`] && (
                <span className="required-mark">
                  {errors[`${dynamicFieldName}.Experience`]}
                </span>
              )}
            </div>

            <div className={`input-field`}>
              <div className="field-label-container">
                <label>Industry</label>
              </div>
              <input
                type="text"
                className="form-control"
                value={eligibilityChoices.Industry}
                placeholder="Enter Industry Name"
                onChange={(e) => {
                  setEligibilityChoices({
                    ...eligibilityChoices,
                    Industry: e.target.value,
                  });
                }}
              />
              {errors[`${dynamicFieldName}.Industry`] && (
                <span className="required-mark">
                  {errors[`${dynamicFieldName}.Industry`]}
                </span>
              )}
            </div>

            <div className={`input-field`}>
              <div className="field-label-container">
                <label>Company</label>
              </div>
              <input
                type="text"
                className="form-control"
                value={eligibilityChoices.Company}
                placeholder="Enter Company Name"
                onChange={(e) => {
                  setEligibilityChoices({
                    ...eligibilityChoices,
                    Company: e.target.value,
                  });
                }}
              />
              {errors[`${dynamicFieldName}.Company`] && (
                <span className="required-mark">
                  {errors[`${dynamicFieldName}.Company`]}
                </span>
              )}
            </div>
          </>
        )}
      {/* Professional Options Ends */}
    </>
  );
}

export default EligibilityChoicesUserForm;
