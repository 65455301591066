import { Timeline } from "antd";
import axios from "axios";
import $ from "jquery";
import moment from "moment-timezone";
import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Helmet } from "react-helmet";
import {
  FaArrowLeft,
  FaArrowRight,
  FaCalendarAlt,
  FaDownload,
  FaEye,
  FaRegCalendarAlt,
  FaRegListAlt,
  FaUpload,
  FaUserAlt,
  FaUsers,
} from "react-icons/fa";
import Moment from "react-moment";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { Link as Links } from "react-scroll";
import "reactjs-popup/dist/index.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Swal from "sweetalert2";
import { A11y, Autoplay, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import config from "../CommonFiles/config.json";
import Footer from "../CommonFiles/Footer";
import Header from "../CommonFiles/Header";
import ConvertISTToLocal from "../CommonFiles/Utils/ConvertISTToLocal";
import Loader from "../StaticPages/Loader";
import "../StaticPages/swiperstyle/style.css";
import DynamicSubmissionUserForm from "./DynamicSubmissionUserForm";
import "./OpportunityDetail.css";

const mainurl = Object.values(config["URL"]);
const URL = mainurl.join("");
const mainURL = URL;
let rdate;
let tokenid;
var paytmconfig;

function OpportunityDetail(props) {
  const [opportunities, setOpportunities] = useState(null);
  const [rounds, setRounds] = useState([]);
  const [prizes, setPrizes] = useState([]);
  const [ratings, setRatings] = useState([]);
  const [alldata, setAlldata] = useState([]);
  const [smShow, setSmShow] = useState(false);
  const [smShowpayment, setSmShowPayement] = useState(false);
  const [selectedplan, setSelectedplan] = useState("");
  const [afterdiscount, setAfterdiscount] = useState("");
  let [txntokenid, setTxntokenid] = useState();
  const [uploadfile, setuploadfile] = useState("");
  const [tabselectedval, settabselectedval] = useState("rounds");
  const [showloader, setShowloader] = useState(false);
  const [memberregister, setMemberregister] = useState(false);
  const [Couponval, setCouponval] = useState();
  const [imortantdates, setImortantdates] = useState([]);
  const [imortantcontact, setImortantcontact] = useState();
  const [rating, setRating] = useState();
  const [reviewdata, setRreviewdata] = useState([]);
  const [allBanner, setAllbanner] = useState([]);
  const [name, setName] = useState("");
  const [feedback, setFeedback] = useState("");
  let [popupstatus, setPopupstatus] = useState(0);
  let [uploadmsg, setUploadmsg] = useState("");
  let [uploaderrormsg, setUploaderrormsg] = useState("");
  let [error, setError] = useState();
  const [planmsg, setPlanmsg] = useState();
  const [discountsuccess, setDiscountsuccess] = useState();
  const [auth, setAuth] = useState(null);
  const [loginstatus, setLoginstatus] = useState(false);
  const [dateLoad, setDateLoad] = useState(true);
  const [finalTime, setFinalTime] = useState("");
  const [finaltimeforcasestudy, setFinaltimeforcasestudy] = useState("");
  const [finalhoursnew, setFinalhoursnew] = useState("");
  const [finalmintsnew, setFinalmintsnew] = useState("");
  const [finalsenew, setFinalsenew] = useState("");
  const [finaldays, setFinalDays] = useState("");
  const [disableScrollListener, setDisableScrollListener] = useState(false);
  const [showSubmissionModal, setShowSubmissionModal] = useState(false);
  const [submissionModalOpenedForRound, setSubmissionModalOpenedForRound] =
    useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [fileInfo, setFileInfo] = useState({});

  // Add refs to store interval IDs
  const scrollTimerRef = useRef(null);
  const dateTimerRef = useRef(null);
  const roundDateTimerRef = useRef(null);
  const roundCaseStudyTimerRef = useRef(null);

  let finalhours = "";
  let finalmints = "";
  let finalse = "";
  let authUser = JSON.parse(localStorage.getItem("alldata"));
  moment.tz.setDefault("Asua/Kolkata");

  // To set selected tab based on scroll position
  useEffect(() => {
    const handleScroll = () => {
      if (!disableScrollListener) {
        const divs = document.querySelectorAll(".scroll-div");
        const scrollY = window.scrollY || window.pageYOffset;

        divs.forEach((div) => {
          const rect = div.getBoundingClientRect();
          const offsetTop = rect.top + scrollY;

          // Adjusting the visibility threshold based on your layout
          const visibleThreshold = window.innerHeight * 0.5; // Adjust as needed

          if (offsetTop < scrollY + visibleThreshold) {
            settabselectedval(div.id);
          }
        });
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      if (scrollTimerRef.current) {
        clearTimeout(scrollTimerRef.current);
      }
    };
  }, [disableScrollListener]);

  // eslint-disable-next-line
  useEffect(async () => {
    window.scrollTo(0, 0);
    localStorage.setItem("loginpopup", false);

    let queryParams = "";
    if (authUser) {
      setAuth(authUser);
      queryParams = "?user_id=" + authUser.id;
    }

    setShowloader(true);

    try {
      const result = await axios({
        method: "get",
        url: mainURL + "/opportunity/" + props.match.params.id + queryParams,
      });
      // Convert IST to Local
      const registrationStart = ConvertISTToLocal(
        result.data.data.register_start_date,
        result.data.data.register_start_time
      );
      const registrationEnd = ConvertISTToLocal(
        result.data.data.register_end_date,
        result.data.data.register_end_time
      );
      const opportinityStart = ConvertISTToLocal(
        result.data.data.start_date,
        result.data.data.start_time
      );
      const opportinityEnd = ConvertISTToLocal(
        result.data.data.end_date,
        result.data.data.end_time
      );

      const resultData = {
        ...result.data.data,
        register_start_date: registrationStart.localDate,
        register_start_time: registrationStart.localTime,
        register_end_date: registrationEnd.localDate,
        register_end_time: registrationEnd.localTime,
        start_date: opportinityStart.localDate,
        start_time: opportinityStart.localTime,
        end_date: opportinityEnd.localDate,
        end_time: opportinityEnd.localTime,
        localTimeZone: registrationStart.localAbbreviation,
      };

      setAlldata(resultData);

      const rounds = result.data.rounds;

      // Convert rounds date and times to Local
      for (let i = 0; i < rounds.length; i++) {
        if (rounds[i].start_date && rounds[i].start_time) {
          rounds[i].start_date = ConvertISTToLocal(
        rounds[i].start_date,
        rounds[i].start_time
          ).localDate;
          rounds[i].start_time = ConvertISTToLocal(
        rounds[i].start_date,
        rounds[i].start_time
          ).localTime;
        } else {
          rounds[i].start_date = null;
          rounds[i].start_time = null;
        }

        if (rounds[i].end_date && rounds[i].end_time) {
          rounds[i].end_date = ConvertISTToLocal(
        rounds[i].end_date,
        rounds[i].end_time
          ).localDate;
          rounds[i].end_time = ConvertISTToLocal(
        rounds[i].end_date,
        rounds[i].end_time
          ).localTime;
        } else {
          rounds[i].end_date = null;
          rounds[i].end_time = null;
        }
      }

      setRounds(rounds);
      setPrizes(result.data.prizes);
      setRatings(result.data.ratings);
      const datess = JSON.parse(result.data.data.important_dates);
      const contacts = JSON.parse(result.data.data.important_contacts);
      setImortantdates(datess.filter((d) => d.date !== ""));
      setImortantcontact(
        contacts.filter((c) => c.email !== "" || c.contact !== "")
      );
      rdate = alldata.register_end_date;

      localStorage.setItem("eventid", resultData.id);
      localStorage.setItem("opportunitydata", JSON.stringify(resultData));
      try {
        const bannerresult = await axios({
          method: "get",
          url: mainURL + "/opportunity/banner/" + resultData.id,
        });
        setAllbanner(bannerresult.data.data);
        // lazy load the banner images without intersection oberserer
        bannerresult.data.data.forEach((banner) => {
          const img = new Image();
          img.src = banner.image;
        });
        setShowloader(false);
      } catch (error) {
        console.log(error);
      }
      localStorage.setItem("eventslug", resultData.slug);
    } catch (error) {
      console.log(error);
    }
    return () => {};
  }, []);

  function submitSubmission(submission_data, round_id, submission_id, method) {
    const USER_TOKEN = localStorage.getItem("token");
    const AuthStr = "Bearer ".concat(USER_TOKEN);
    const payload = {
      userId: authUser.id,
      opportunityId: alldata.id,
      roundId: round_id,
      submission: submission_data,
    };

    axios({
      method: method,
      url:
        mainURL +
        `/v1/submissions${method === "put" ? "/" + submission_id : ""}`,
      data: payload,
      headers: { Authorization: AuthStr },
    })
      .then((response) => {
        Swal.fire(
          "Success",
          "Submission has been successfully submitted",
          "success"
        ).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
      })
      .catch(function (error) {
        //handle error
        if (error?.response?.data?.auth === false) {
          Swal.fire({
            title: "Your session has expired, please login again!",
            icon: "warning",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            allowOutsideClick: false,
            cancelButtonColor: "#d33",
            confirmButtonText: "Continue",
          }).then((result) => {
            if (result.isConfirmed) {
              localStorage.clear();
              window.location.href = "/";
            }
          });
        } else {
          Swal.fire({
            title: "Sorry, some error occured, please try again",
            icon: "error",
          });
        }
      });
  }

  const deleteSubmission = (submission_id) => {
    const USER_TOKEN = localStorage.getItem("token");
    const AuthStr = "Bearer ".concat(USER_TOKEN);

    Swal.fire({
      title: "Are you sure you want to delete this submission?",
      icon: "warning",
      showCancelButton: true,
      allowOutsideClick: false,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "delete",
          url: mainURL + "/opportunity/submissions/" + submission_id,
          headers: { Authorization: AuthStr },
        })
          .then((response) => {
            Swal.fire(
              "Success",
              "Submission has been successfully deleted",
              "success"
            ).then((result) => {
              if (result.isConfirmed) {
                window.location.reload();
              }
            });
          })
          .catch(function (error) {
            //handle error
            if (error?.response?.data?.auth === false) {
              Swal.fire({
                title: "Your session has expired, please login again!",
                icon: "warning",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                allowOutsideClick: false,
                cancelButtonColor: "#d33",
                confirmButtonText: "Continue",
              }).then((result) => {
                if (result.isConfirmed) {
                  localStorage.clear();
                  window.location.href = "/";
                }
              });
            } else {
              Swal.fire({
                title: "Sorry, some error occured, please try again",
                icon: "error",
              });
            }
          });
      }
    });
  };

  function planselect(e) {
    setSelectedplan(e.target.value);
  }
  function choosePlane(status) {
    var errorexits = document.querySelectorAll("#fill-the-field");
    if (errorexits.length > 0) {
      setError("Please fill all the fields");
    }
    if (errorexits.length > 0) {
      return false;
    }

    setSmShow(status);
  }
  useEffect(() => {
    if (alldata) {
      setShowloader(true);
      dateFunction(alldata.register_end_date, alldata.register_end_time);
    }
  }, [alldata]);
  function discountapply() {
    if (!selectedplan) {
      setPlanmsg("Please select plan first");
    } else {
      const USER_TOKEN = localStorage.getItem("token");
      console.log("user token", USER_TOKEN);
      const AuthStr = "Bearer ".concat(USER_TOKEN);
      let totalamount;
      axios({
        method: "post",
        url: mainURL + "/apply/coupons",
        data: {
          // user_id
          opportunity_id: alldata.id,
          name: Couponval,
        },
        headers: { Authorization: AuthStr },
      })
        .then((response) => {
          if (response.data.data === null) {
            setPlanmsg("Invalid coupon!");
          } else {
            if (response.data.data.coupon_type) {
              totalamount =
                selectedplan - parseInt(response.data.data.coupon_value);
              setDiscountsuccess(
                "Discount of Rs" +
                  parseInt(response.data.data.coupon_value) +
                  " successfully applied!"
              );
              setAfterdiscount(totalamount);
              setPlanmsg("");
            } else {
              totalamount =
                selectedplan -
                (selectedplan * parseInt(response.data.data.coupon_value)) /
                  100;
              setAfterdiscount(totalamount);
              setPlanmsg("");
              setDiscountsuccess(
                "Discount of Rs" +
                  (selectedplan * parseInt(response.data.data.coupon_value)) /
                    100 +
                  " successfully applied!"
              );
            }
          }
        })
        .catch(function (response) {
          //handle error
          console.log("Error while calling api", response);
        });
    }
  }
  function onScriptLoad(id) {
    console.log("selectedplan", selectedplan);

    if (!selectedplan) {
      setPlanmsg("Please select plan first");
    } else {
      let alldatanew;
      let orderid = Math.floor(Math.random() * 9999999999);

      const USER_TOKEN = localStorage.getItem("token");
      console.log("user token", USER_TOKEN);
      const AuthStr = "Bearer ".concat(USER_TOKEN);
      axios({
        method: "post",
        url: mainURL + "/opportunity/register",
        data: {
          // user_id
          order_id_old: alldata.order_id,
          order_id_new: orderid,
        },
        headers: { Authorization: AuthStr },
      })
        .then(() => {
          let planprice;
          if (afterdiscount) {
            planprice = afterdiscount;
          } else {
            planprice = selectedplan;
          }
          axios({
            method: "post",
            url: "http://13.232.79.236:7000/api/paynow",
            data: {
              orderid: orderid,
              amount: planprice,
              mobile: authUser.mobile,
              email: authUser.email,
            },
          })
            .then((response) => {
              alldatanew = response.data.body.txnToken; // i got the token from the paytm api
              setTxntokenid(alldatanew);
              tokenid = alldatanew;

              var MID = "GRADPA33600440875116";
              var URL =
                "https://securegw.paytm.in/merchantpgpui/checkoutjs/GRADPA33600440875116";
              var merchantCallback = null;
              var createDOMElements = function (input) {
                var scriptEle = document.createElement("script"),
                  cssEle = document.createElement("link");

                if (cssEle) {
                  cssEle.href = input.style;
                  cssEle.rel = "stylesheet";
                  cssEle.type = "text/css";
                  document.head.appendChild(cssEle);
                }
                if (scriptEle) {
                  scriptEle.async = true;
                  scriptEle.src = input.js;
                  scriptEle.type = "application/javascript";
                  scriptEle.crossOrigin = "anonymous";
                  scriptEle.onload = function () {
                    if (window.CheckoutJS) {
                      if (window.Paytm && window.Paytm.CheckoutJS) {
                        for (var key in window.CheckoutJS) {
                          if (window.CheckoutJS.hasOwnProperty(key)) {
                            window.Paytm.CheckoutJS[key] =
                              window.CheckoutJS[key];
                          }
                        }
                      }
                      try {
                        delete window.CheckoutJS; // remove CheckoutJS from window scope
                      } catch (e) {}
                      if (window.Paytm.CheckoutJS.initLib) {
                        window.Paytm.CheckoutJS.initLib(MID).then(function () {
                          if (merchantCallback) {
                            merchantCallback.call();
                          }
                        });
                      }
                    }
                  };
                  document.body.appendChild(scriptEle);
                }
              };

              var post = function () {
                var xhr = new XMLHttpRequest();
                xhr.onreadystatechange = function () {
                  if (this.readyState === 4) {
                    var data = JSON.parse(this.responseText);
                    console.log("response data", data);
                    createDOMElements(data);
                  }
                };
                xhr.open("GET", URL, true);
                xhr.setRequestHeader(
                  "Content-Type",
                  "application/javascript; charset=utf-8"
                );
                xhr.send(null);
              };

              post();

              if (!window.Paytm) {
                // check if window.Paytm exists or not
                window.Paytm = {};
              }

              window.Paytm.CheckoutJS = {
                onLoad: function (callback) {
                  if (!callback || callback.constructor !== Function) {
                    throw new Error(
                      "callback in onLoad function should be of function type"
                    );
                  }
                  merchantCallback = callback;
                },
              };

              paytmconfig = {
                root: "",
                flow: "DEFAULT",
                data: {
                  orderId: orderid /* update order id */,
                  token: tokenid /* update token value */,
                  tokenType: "TXN_TOKEN",
                  amount: "4.00" /* update amount */,
                },
                handler: {
                  notifyMerchant: function () {
                    console.log("notifyMerchant handler function called");
                  },
                },
              };
              setShowloader(true);
              setSmShow(false);

              if (window.Paytm && window.Paytm.CheckoutJS) {
                window.Paytm.CheckoutJS.onLoad(
                  function excecuteAfterCompleteLoad() {
                    // initialze configuration using init method
                    window.Paytm.CheckoutJS.init(paytmconfig)
                      .then(function onSuccess() {
                        setShowloader(false);

                        // after successfully updating configuration, invoke JS Checkout
                        window.Paytm.CheckoutJS.invoke();
                      })
                      .catch(function onError() {});
                  }
                );
              }
            })
            .catch(() => {});
        })
        .catch(function () {
          //handle error
        });
    }
  }

  const tabselected = (tab) => {
    setDisableScrollListener(true); // Disable scroll listener temporarily
    settabselectedval(tab);
    scrollTimerRef.current = setTimeout(() => {
      setDisableScrollListener(false); // Re-enable scroll listener after a short delay
    }, 1000);
  };

  function gotoquizdetail(event, slug, roundID) {
    event.stopPropagation();
    localStorage.setItem("selectedRound", roundID);
    props.history.push("/e/" + slug + "/round-1", { id: 7, color: "green" });
  }

  const registerlist = () => {
    props.history.push(`/user`);
  };

  const registernowclic = () => {
    const USER_TOKEN = localStorage.getItem("token");
    if (USER_TOKEN) {
      if (alldata.external_registration) {
        window.open(alldata.registration_link, "_blank");
      } else {
        props.history.push(
          `/opportunities/detail/registration/${props.match.params.id}`
        );
      }
    } else {
      setLoginstatus(false);
      if (localStorage.getItem("loginpopup") === "false") {
        setLoginstatus(true);
        localStorage.setItem("loginpopup", "true");
      }
    }
  };

  function couponvalenter(e) {
    setCouponval(e.target.value);
  }

  const fixslider = [
    {
      image: require("../../images/fixslider/1.png").default,
    },
    {
      image: require("../../images/fixslider/2.png").default,
    },
    {
      image: require("../../images/fixslider/3.png").default,
    },
    {
      image: require("../../images/fixslider/3.1.png").default,
    },
    {
      image: require("../../images/fixslider/3-2.png").default,
    },
  ];

  const RoundDateFunctionstatus = (item) => {
    const getDatefrom = moment(item.end_date)
      .tz("Asia/Kolkata")
      .format("YYYY-MM-DD");
    const finaltimene = moment
      .utc(getDatefrom + " " + item.end_time)
      .add(59, "seconds")
      .format("YYYY-MM-DD HH:mm:ss");

    var duration = moment.duration(
      moment(finaltimene).diff(moment(new Date()))
    );
    var sec = duration.seconds();

    var interval = 1000;
    if (sec > 0) {
      roundDateTimerRef.current = setInterval(function () {
        var duration = moment.duration(
          moment(finaltimene).diff(moment(new Date()))
        );
        var sec = duration.seconds();
        if (sec < 0) {
          setFinaltimeforcasestudy("end");
        }
      }, interval);
      return (
        <>
          <span className="roundstatus">
            <i className="fa fa-circle"> </i>
            Live
          </span>
        </>
      );
    }
  };

  const renderDynamicSubmissionUserModal = () => {
    const isOpen = showSubmissionModal && submissionModalOpenedForRound;
    const round = submissionModalOpenedForRound;
    const roundEndTime = round
      ? moment(round.end_date).set({
          hour: parseInt(round.end_time.split(":")[0], 10),
          minute: parseInt(round.end_time.split(":")[1], 10),
        })
      : null;

    const submissionId =
      round &&
      round.submissions.length > 0 &&
      round.submissions[0].submission_data
        ? round.submissions[0].id
        : null;

    if (!isOpen) return <></>;

    return (
      <DynamicSubmissionUserForm
        isOpen={isOpen}
        roundEndTime={roundEndTime}
        submissionSettings={
          round && round.submission_settings
            ? JSON.parse(round.submission_settings)
            : null
        }
        submissionData={
          round && round.submissions[0]?.submission_data
            ? JSON.parse(round.submissions[0].submission_data)
            : null
        }
        onClose={() => {
          setShowSubmissionModal(false);
          setSubmissionModalOpenedForRound(null);
        }}
        onSubmit={(data) => {
          if (!data) {
            return;
          } else {
            setShowSubmissionModal(false);
            const method = submissionId ? "put" : "post";
            submitSubmission(data, round.id, submissionId, method);
          }
        }}
        onDeleteSubmission={() => {
          setShowSubmissionModal(false);
          if (round && roundEndTime.add(59, "seconds").isBefore(moment())) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Deadline is over, better luck next time!",
            });
          } else {
            deleteSubmission(submissionId);
          }
        }}
      />
    );
  };

  const RoundDateFunction = (round, roundIndex) => {
    const getDatefrom = moment(round.end_date)
      .tz("Asia/Kolkata")
      .format("YYYY-MM-DD");
    const finaltimene = moment
      .utc(getDatefrom + " " + round.end_time)
      .add(59, "seconds")
      .format("YYYY-MM-DD HH:mm:ss");

    const startDateAndTime = moment
      .utc(
        moment(round.start_date).tz("Asia/Kolkata").format("YYYY-MM-DD") +
          " " +
          round.start_time
      )
      .format("YYYY-MM-DD HH:mm:ss");

    var duration = moment.duration(
      moment(finaltimene).diff(moment(new Date()))
    );
    var days = Math.floor(duration.asDays());
    var hours = duration.hours();
    var mins = duration.minutes();
    var sec = duration.seconds();

    var interval = 1000;

    if (days <= 0 && hours <= 0 && mins <= 0 && sec <= 0) {
      setFinaltimeforcasestudy("end");
      return;
    }

    if (
      moment().isBetween(startDateAndTime, finaltimene) &&
      days >= 0 &&
      sec >= 0
    ) {
      // Clear any existing interval
      if (roundCaseStudyTimerRef.current) {
        clearInterval(roundCaseStudyTimerRef.current);
      }

      // Create new interval
      roundCaseStudyTimerRef.current = setInterval(function () {
        var duration = moment.duration(
          moment(finaltimene).diff(moment(new Date()))
        );
        var sec = duration.seconds();
        if (sec < 0) {
          setFinaltimeforcasestudy("end");
        }
      }, interval);

      const eligibleForCaseStudyView =
        roundIndex !== 0
          ? !!alldata?.isregistersUsers?.current_round &&
            alldata?.isregistersUsers?.current_round === round.id
          : true;

      const canUploadSubmission =
        alldata?.isAllRegistered === 1 &&
        eligibleForCaseStudyView &&
        alldata?.is_leader &&
        round?.type === "submission";

      const submissionSettings =
        round?.type === "submission" &&
        round?.submission_settings &&
        typeof round.submission_settings === "string" &&
        round.submission_settings.trim() !== ""
          ? JSON.parse(round.submission_settings)
          : null;

      const canDownloadCaseStudy =
        alldata?.isAllRegistered === 1 &&
        eligibleForCaseStudyView &&
        round?.type === "submission" &&
        submissionSettings &&
        submissionSettings.showCaseStudy &&
        submissionSettings.caseStudyData.length > 0;

      return (
        <>
          <div className="fr casestudy">
            <>
              {canDownloadCaseStudy &&
                submissionSettings?.caseStudyData.map((caseStudy, index) => (
                  <label className="btn menubtn detail-download">
                    <FaDownload />
                    &nbsp;
                    <a
                      href={caseStudy.fileUrl}
                      target="_blank"
                      download
                      rel="noreferrer"
                    >
                      Case Study
                    </a>
                  </label>
                ))}
              {canUploadSubmission && (
                <>
                  <div className="meeting-cards ">
                    <div className="meeting-detail float-right">
                      <label
                        className="fileUpload btn menubtn"
                        onClick={() => {
                          setSubmissionModalOpenedForRound(round);
                          setShowSubmissionModal(true);
                        }}
                      >
                        <FaUpload />
                        &nbsp;
                        {round?.submissions.length > 0
                          ? "Change Submission"
                          : "Submission"}
                      </label>
                    </div>
                  </div>
                </>
              )}
            </>
          </div>
        </>
      );
    }
  };
  const dateFunction = (getDate, getTime) => {
    const getDatefrom = moment(getDate).tz("Asia/Kolkata").format("YYYY-MM-DD");
    const finaltime = moment
      .utc(getDatefrom + " " + getTime)
      .add(59, "seconds")
      .format("YYYY-MM-DD HH:mm:ss");

    if (finalTime !== "end") {
      dateTimerRef.current = setInterval(function () {
        var duration = moment.duration(
          moment(finaltime).diff(moment(new Date()))
        );
        var days = Math.floor(duration.asDays());
        var hours = duration.hours();
        var mins = duration.minutes();
        var sec = duration.seconds();
        if (days > 0) {
          setFinalTime("start");

          if (days === 1) {
            $(".timeshow").text(days + " Day Left");
          } else {
            $(".timeshow").text(days + " Days Left");
          }
        }
        setFinalDays(days);
        if (days === 0 && sec >= 0) {
          setFinalTime("start");

          finalhours = hours < 10 ? "0" + hours : hours;
          finalmints = mins < 10 ? "0" + mins : mins;
          finalse = sec < 10 ? "0" + sec : sec;

          setFinalhoursnew(finalhours);
          setFinalmintsnew(finalmints);
          setFinalsenew(finalse);

          $(".timeshow").text("Last Day");
        }
        if (days <= 0 && hours <= 0 && mins <= 0 && sec <= 0) {
          setFinalTime("end");
        }
        setDateLoad(false);
        setShowloader(false);
      }, 1000);
    }
  };
  const handleAutoplay = (swiper) => {
    if (allBanner.length > 1) {
      swiper.autoplay.start();
    } else {
      swiper.autoplay.stop();
    }
  };

  // Add cleanup effect for all intervals
  useEffect(() => {
    return () => {
      // Cleanup all intervals and timeouts when component unmounts
      if (dateTimerRef.current) {
        clearInterval(dateTimerRef.current);
      }
      if (roundDateTimerRef.current) {
        clearInterval(roundDateTimerRef.current);
      }
      if (scrollTimerRef.current) {
        clearTimeout(scrollTimerRef.current);
      }
    };
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <meta name="description" content={alldata && alldata.about_company} />
        <meta
          property="og:title"
          content={alldata && alldata.title + "| Grad Partners"}
        />
        {/* <meta property="og:image" content="{alldata.logo_image}" /> */}
        <meta property="og:url" content="https://www.gradpartners.in/" />
      </Helmet>

      <Header openlogin={loginstatus} registernowclic={registernowclic} />
      <div className="opportunity-detail-body">
        <section className=" pt-md-5 mt-5 mobile-verion">
          <div className="container">
            <div className="col-md-9 submission-closed-section offset-md-1 px-0">
              <div className="row submission-detail  od-card">
                <div className="col-md-12 px-0 ipad-full-width">
                  <Col className="right px-0">
                    <div className="">
                      <div className="img-title">
                        <img
                          src={alldata.logo_image}
                          className="opplogomobile"
                          alt="opportunity-logo"
                        />

                        <div className="od-rightside-heading">
                          <h5>{alldata.title}</h5>
                          <br />
                          <p className="rightside-sub-heading">
                            {alldata.organization}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6 px-0 ipad-full-width">
                        <Col className="left px-0">
                          <div className="od-card-leftside">
                            <div className="od-leftside-stats">
                              <Row className="stat-card-container">
                                <div className="stat-card">
                                  <FaCalendarAlt />
                                  {/* <p>Coming Soon</p> */}

                                  {alldata.title ===
                                  "HCCB India Case Challenge Season 1" ? (
                                    <p>
                                      1st Jul - <br /> 20th Aug
                                    </p>
                                  ) : alldata.slug ===
                                    "BlueKaktus-StylAce-2021-14" ? (
                                    <p>
                                      1st Aug - <br />
                                      31st Oct
                                    </p>
                                  ) : (
                                    <>
                                      <p>
                                        {moment(alldata.register_end_date).diff(
                                          moment(new Date()),
                                          "days"
                                        ) <= 0
                                          ? " Closed"
                                          : moment(alldata.register_end_date)
                                                .tz("Asia/Calcutta")
                                                .diff(
                                                  moment(new Date()).tz(
                                                    "Asia/Calcutta"
                                                  ),
                                                  "days"
                                                ) +
                                                1 +
                                                moment(
                                                  alldata.register_end_date
                                                ).diff(
                                                  moment(new Date()),
                                                  "days"
                                                ) ===
                                              1
                                            ? "1 Day Left"
                                            : " Days Left"}
                                      </p>
                                    </>
                                  )}
                                </div>
                                <div className="stat-card">
                                  <FaEye />
                                  <p>
                                    {alldata.views
                                      ? new Intl.NumberFormat("en-IN").format(
                                          alldata.views
                                        )
                                      : ""}{" "}
                                  </p>
                                  <p>Views</p>
                                </div>
                                <div className="stat-card">
                                  <FaUsers />
                                  <p>
                                    {alldata.min_team_size ===
                                    alldata.max_team_size
                                      ? alldata.max_team_size
                                      : alldata.min_team_size === 0
                                        ? "1"
                                        : alldata.min_team_size +
                                          "-" +
                                          alldata.max_team_size}{" "}
                                  </p>
                                  <p>Team Size</p>
                                </div>
                                <div className="stat-card">
                                  <FaUserAlt />
                                  <p>
                                    {" "}
                                    {alldata.register_count === 0
                                      ? "Free"
                                      : new Intl.NumberFormat("en-IN").format(
                                          alldata.register_count
                                        )}
                                  </p>
                                  <p>Registrations</p>
                                </div>
                              </Row>
                            </div>
                          </div>
                        </Col>
                      </div>

                      <div>
                        <div className="sub-round-box">
                          <div>
                            <FaUserAlt />
                            <p className="mb-0 sub-round-box-num">
                              {" "}
                              {alldata.register_count === 0
                                ? "Paid"
                                : alldata.register_count &&
                                  new Intl.NumberFormat("en-IN").format(
                                    alldata.register_count
                                  )}{" "}
                            </p>
                            <p className="mb-0"> Registrations </p>
                          </div>

                          <div>
                            <i className="far fa-calendar-alt"></i>
                            <p className="mb-0 sub-round-box-num">
                              {" "}
                              {alldata.min_team_size === alldata.max_team_size
                                ? alldata.max_team_size
                                : alldata.min_team_size === 0
                                  ? "1"
                                  : alldata.min_team_size -
                                    alldata.max_team_size}{" "}
                            </p>
                            <p className="mb-0"> Deadline </p>
                          </div>

                          <div>
                            <FaEye />
                            <p className="mb-0 sub-round-box-num">
                              {" "}
                              {alldata.views
                                ? new Intl.NumberFormat("en-IN").format(
                                    alldata.views
                                  )
                                : ""}{" "}
                            </p>
                            <p className="mb-0"> Views </p>
                          </div>
                        </div>
                        <div className="od-leftside-heading">
                          {/* add title for header */}
                        </div>
                      </div>
                      {/* When logged out */}
                      <div className="sub-round-box-registeration-btn">
                        {new Date(alldata.register_end_date) < new Date() &&
                        alldata.is_registered === false ? (
                          <Button type="primary">
                            <FaUserAlt />
                            Registration Closed
                          </Button>
                        ) : new Date(alldata.register_end_date) < new Date() &&
                          alldata.is_registered === true ? (
                          alldata.isAllRegistered === 1 ? (
                            <Button type="primary">
                              <FaUserAlt />
                              Registered
                            </Button>
                          ) : (
                            <Button type="primary">
                              <FaUserAlt />
                              Incomplete Team <br />
                              <i className="fa fa-edit"></i>&nbsp; (Check team
                              Status)
                            </Button>
                          )
                        ) : alldata.category === "workshop" ? (
                          alldata.is_registered === true &&
                          alldata.is_paid === true ? (
                            <Button type="primary">
                              <FaUserAlt />
                              Registered
                            </Button>
                          ) : alldata.is_registered === true &&
                            alldata.is_paid === false ? (
                            <button
                              className=""
                              onClick={() => choosePlane(true)}
                            >
                              <FaUserAlt />
                              Pay Now
                            </button>
                          ) : (
                            // <Link
                            //   to={`/opportunities/detail/registration/${props.match.params.id}`}
                            // >
                            <Button
                              type="primary"
                              onClick={() => registernowclic()}
                            >
                              <FaUserAlt />
                              Register Now
                            </Button>
                            // </Link>
                          )
                        ) : alldata.is_registered === true ? (
                          alldata.isAllRegistered === 1 ? (
                            <Button type="primary">
                              <FaUserAlt />
                              Registered
                            </Button>
                          ) : (
                            <Button type="primary">
                              <FaUserAlt />
                              Incomplete Team <br /> &nbsp;
                              <i className="fa fa-edit"></i>&nbsp; (Check team
                              Status)
                            </Button>
                          )
                        ) : moment(new Date())
                            .tz("Asia/Kolkata")
                            .diff(
                              moment(alldata.register_end_date)
                                .tz("Asia/Kolkata")
                                .subtract(5, "hours")
                                .subtract(30, "minutes")
                            ) < 0 ? (
                          <Button type="primary">
                            <FaUserAlt />
                            Registration Closed
                          </Button>
                        ) : (
                          <Button
                            type="primary"
                            onClick={() => registernowclic()}
                          >
                            <FaUserAlt />
                            Register Now
                          </Button>
                          // </Link>
                        )}
                      </div>
                    </div>
                  </Col>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Modal
          size="sm"
          show={smShow}
          onHide={() => setSmShow(false)}
          aria-labelledby="example-modal-sizes-title-sm"
          className="plantable"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">Plans</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <table className="table table-bordered">
              <thead className="thead-light">
                <th>Plan Name</th>
                <th>Description</th>
                <th>Registration Fees</th>
              </thead>
              <tbody>
                {alldata?.plans ? (
                  alldata?.plans.map((plan, index) => (
                    <tr>
                      <td>Plan {String.fromCharCode(65 + index)}</td>
                      <td>{plan.description}</td>
                      <td>{plan.price}</td>
                    </tr>
                  ))
                ) : (
                  <>
                    <div className="planoptions">
                      <input
                        type="radio"
                        value="999"
                        name="plan"
                        onChange={(e) => planselect(e)}
                      />{" "}
                      ₹ 999 (4 workshops) <br />
                    </div>
                    <div className="planoptions">
                      <input
                        type="radio"
                        value="599"
                        name="plan"
                        onChange={(e) => planselect(e)}
                      />{" "}
                      ₹ 599 (2 workshops) <br />
                    </div>
                    <div className="planoptions">
                      <input
                        type="radio"
                        value="799"
                        name="plan"
                        onChange={(e) => planselect(e)}
                      />{" "}
                      ₹ 799 (4 workshops, for existing AceTheCase subscribers){" "}
                      <br />
                    </div>
                    <div className="planoptions">
                      <input
                        type="radio"
                        value="499"
                        name="plan"
                        onChange={(e) => planselect(e)}
                      />{" "}
                      ₹ 499 (2 workshops, for existing AceTheCase subscribers)
                    </div>
                  </>
                )}
              </tbody>
            </table>
          </Modal.Body>
        </Modal>

        {/* for payment */}
        <Modal
          size="sm"
          show={smShowpayment}
          onHide={() => setSmShowPayement(false)}
          aria-labelledby="example-modal-sizes-title-sm"
          className="plantable"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">Plans</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <table className="table table-bordered">
              <thead className="thead-light">
                <th>Plan Name</th>
                <th>Description</th>
                <th>Registration Fees</th>
              </thead>
              <tbody>
                {alldata.plans ? (
                  alldata.plans.map((plan, index) => (
                    <tr>
                      <td>Plan {String.fromCharCode(65 + index)}</td>
                      <td>{plan.description}</td>
                      <td>{plan.price}</td>
                    </tr>
                  ))
                ) : (
                  <>
                    <div className="planoptions">
                      <input
                        type="radio"
                        value="999"
                        name="plan"
                        onChange={(e) => planselect(e)}
                      />{" "}
                      ₹ 999 (4 workshops) <br />
                    </div>
                    <div className="planoptions">
                      <input
                        type="radio"
                        value="599"
                        name="plan"
                        onChange={(e) => planselect(e)}
                      />{" "}
                      ₹ 599 (2 workshops) <br />
                    </div>
                    <div className="planoptions">
                      <input
                        type="radio"
                        value="799"
                        name="plan"
                        onChange={(e) => planselect(e)}
                      />{" "}
                      ₹ 799 (4 workshops, for existing AceTheCase subscribers){" "}
                      <br />
                    </div>
                    <div className="planoptions">
                      <input
                        type="radio"
                        value="499"
                        name="plan"
                        onChange={(e) => planselect(e)}
                      />{" "}
                      ₹ 499 (2 workshops, for existing AceTheCase subscribers)
                    </div>
                  </>
                )}
              </tbody>
            </table>

            <label>Coupon Code</label>
            <div className=" text-left">
              <input
                type="text"
                className="coupon-code"
                name="plan"
                onChange={(e) => couponvalenter(e)}
              />{" "}
              <button className="btn applybtn" onClick={() => discountapply()}>
                Apply
              </button>
            </div>
            <br />

            {planmsg ? (
              <p className="alert alert-danger">{planmsg}</p>
            ) : (
              discountsuccess && (
                <p className="alert alert-success">{discountsuccess}</p>
              )
            )}

            <div>
              {" "}
              <button
                className="btn menubtn paynow"
                onClick={() => onScriptLoad(alldata.id)}
              >
                Pay now
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {!showloader && (
          <section
            className={`od-banner ${allBanner.length > 0 || alldata.banner_image ? "" : "no-banner"} pt-md-5 mt-5 tab-to-desktop`}
          >
            <div className="container resgistration-closed-padding">
              <div className="display_flex">
                <div className="logo_image desktoplogo">
                  {" "}
                  <img
                    className="list-icon-img "
                    src={alldata.logo_image}
                    alt="od-banner"
                  />
                </div>
                <div style={{ color: "#fff" }}>
                  <span className="opportinity-title">{alldata.title}</span>
                  <br />
                  <span className="second_row_css">
                    {" "}
                    <span className="bold-800 timeli">Timelines: </span>{" "}
                    <Moment format="Do MMM'YY" date={alldata.start_date} /> -{" "}
                    <Moment format="Do MMM'YY" date={alldata.end_date} />{" "}
                    <span className="fordesktop ml-3">|</span>
                  </span>
                  <span className="second_row_css">
                    <span className="bold-800">Registration Deadline: </span>
                    <Moment format="Do MMM'YY" date={alldata.register_end_date} />
                    , {alldata.register_end_time} hrs ({alldata.localTimeZone})
                    <span className="fordesktop ml-3">|</span>
                  </span>
                  <span className="second_row_css">
                    <span className="bold-800">Participation: </span>
                    {alldata.max_team_size > 1 ? (
                      <>
                        {alldata.min_team_size === alldata.max_team_size
                          ? alldata.max_team_size
                          : alldata.min_team_size +
                          "-" +
                          alldata.max_team_size}{" "}
                        Members{" "}
                      </>
                    ) : (
                      "Individual"
                    )}{" "}
                  </span>

                  <br />
                  {alldata.is_opportunity_paid === 1 && (
                    <span className="second_row_css">
                      <span className="bold-800">Registration Type: </span>
                      Paid
                      <span className="fordesktop ml-3">|</span>
                    </span>
                  )}
                  <span className="second_row_css">
                    <span
                      className="bold-800 pt-2 hostedby"
                      style={{ display: "inline-block" }}
                    >
                      Hosted by:
                    </span>{" "}
                    <a href={alldata.website} target="_blank" rel="noreferrer">
                      {alldata.organization}
                    </a>
                    <span className="fordesktop ml-3">|</span>
                  </span>
                  {alldata.id == 148 && (
                    <span className="second_row_css">
                      <span
                        className="bold-800 pt-2 hostedby"
                        style={{ display: "inline-block" }}
                      >
                        Division:
                      </span>{" "}
                      <span>Trade Marketing & Distribution</span>{" "}
                      <span className="fordesktop ml-3">|</span>
                    </span>
                  )}
                  {alldata.opportunity_open_for && (
                    <span className="second_row_css">
                      <span className="bold-800">Eligibility: </span>
                      {alldata.opportunity_open_for.charAt(0).toUpperCase() +
                        alldata.opportunity_open_for.slice(1)}
                      {alldata.festival && alldata.festivalName && (
                        <span className="fordesktop ml-3">|</span>
                      )}
                    </span>
                  )}
                  {alldata.festival && alldata.festivalName && (
                    <span className="second_row_css">
                      <span className="bold-800">Campaign: </span>
                      <Link
                        to={`/festival/${alldata.festivalSlug !== "" ? alldata.festivalSlug : alldata.festival}`}
                      >
                        {alldata.festivalName}
                      </Link>
                    </span>
                  )}
                </div>
                <div className="logo_image mobilelogo">
                  {" "}
                  <img
                    className="list-icon-img "
                    src={alldata.logo_image}
                    alt="od-banner"
                  />
                </div>
              </div>

              <div className="display_flex margin-40 justify-content-between for-desktop">
                <div className="forth_row">
                  <span>
                    <img
                      src={require("../../detailspage/Icon-Views.png").default}
                      alt="od-banner"
                    />
                    Views:
                    <span className="forthrowSpan">
                      {" "}
                      {new Intl.NumberFormat("en-IN").format(alldata.views)}
                    </span>{" "}
                  </span>
                  {!alldata.external_registration && (
                    <span>
                      &nbsp;&nbsp; | &nbsp;&nbsp;
                      <img
                        src={
                          require("../../detailspage/Icon-Registrations.png")
                            .default
                        }
                        alt="od-banner"
                      />
                      Registrations:{" "}
                      <span className="forthrowSpan">
                        {new Intl.NumberFormat("en-IN").format(
                          alldata.register_count
                        )}
                      </span>{" "}
                      {finalTime && finalTime === "start" && (
                        <>&nbsp;&nbsp; | &nbsp;&nbsp;</>
                      )}
                    </span>
                  )}
                  <span>
                    {finalTime === "start" && alldata && (
                      <>
                        <img
                          src={require("../../detailspage/days_left.png").default}
                          alt="od-banner"
                        />
                        Deadline: {/* {new Date()} */}
                        <span className="forthrowSpan timeshow"></span>
                      </>
                    )}
                  </span>
                </div>
                {finalhoursnew != "" && finalTime === "start" && (
                  <div className="timmerdisplaydesktop">
                    <span>
                      <div id="timer" className="d-flex justify-content-center">
                        <span className="stopwatchText">Time Left</span>
                        <div className="text-center">
                          <p className="timeandsec mb-0">{finalhoursnew} </p>
                          <p className="statictext mb-1">hours</p>
                        </div>
                        <div className="mx-1">
                          <p className=" dotcolor mb-0">: </p>
                        </div>
                        <div className="text-center">
                          <p className="timeandsec mb-0">{finalmintsnew} </p>
                          <p className="statictext mb-1">minutes</p>
                        </div>
                        <div className="mx-1">
                          <p className="dotcolor mb-0">: </p>
                        </div>
                        <div className="text-center">
                          <p className="timeandsec mb-0">{finalsenew} </p>
                          <p className="statictext mb-1">seconds</p>
                        </div>
                      </div>
                    </span>
                  </div>
                )}
              </div>

              {/* for mobile */}
              <div className="display_flex margin-40 for-mobile">
                <div
                  className={
                    moment(alldata.register_end_date).diff(
                      moment(new Date()),
                      "days"
                    ) -
                      1 <
                      0
                      ? "forth_row withouttab"
                      : "forth_row"
                  }
                >
                  <span>
                    <img
                      src={require("../../detailspage/Icon-Views.png").default}
                      alt="od-banner"
                    />
                    <span className="forthrowSpan">
                      {new Intl.NumberFormat("en-IN").format(alldata.views)}
                    </span>{" "}
                  </span>
                  {!alldata.external_registration && (
                    <>
                      {" "}
                      &nbsp;&nbsp; | &nbsp;&nbsp;
                      <span>
                        <img
                          src={
                            require("../../detailspage/Icon-Registrations.png")
                              .default
                          }
                          alt="od-banner"
                        />
                        <span className="forthrowSpan">
                          {new Intl.NumberFormat("en-IN").format(
                            alldata.register_count
                          )}
                        </span>{" "}
                        {finalTime === "start" && (
                          <>&nbsp;&nbsp; | &nbsp;&nbsp;</>
                        )}
                      </span>
                    </>
                  )}
                  <span className="buttnformobile">
                    {finalTime === "start" && (
                      <>
                        <img
                          src={require("../../detailspage/days_left.png").default}
                          alt="od-banner"
                        />
                        <span className="forthrowSpan timeshow"></span>
                      </>
                    )}
                  </span>
                </div>
              </div>
              {/* When logged in */}
              <div className="display_flex margin-40">
                {dateLoad === false && (
                  <span className="buttnformobile">
                    {localStorage.getItem("token") ? (
                      alldata.is_registered === true ? (
                        alldata.isAllRegistered === 1 ? (
                          <Button
                            type="primary"
                            className="completeteam"
                            onClick={() => registerlist()}
                          >
                            Registered (Click to View)
                          </Button>
                        ) : (
                          <Button
                            type="primary"
                            onClick={() => registerlist()}
                            className="incompleteteam menubtn"
                          >
                            Incomplete Team <br />
                            <i className="fa fa-edit"></i>&nbsp; (Check team
                            Status)
                          </Button>
                        )
                      ) : dateLoad === false &&
                        finalTime === "end" &&
                        alldata.is_registered === false ? (
                        <>
                          {/* <h3>{"step2"}</h3> */}
                          <Button type="primary" className="reg_bttn">
                            Registration Closed
                          </Button>
                        </>
                      ) : (
                        dateLoad === false &&
                        finalTime !== "end" &&
                        alldata.is_registered === false && (
                          <>
                            {/* <h3>{"step3"}</h3> */}
                            <Button
                              type="primary"
                              className="reg_bttn"
                              onClick={() => registernowclic()}
                            >
                              Register Now <FaArrowRight />
                            </Button>
                          </>
                        )
                      )
                    ) : (dateLoad === false && finalTime === "") ||
                      finalTime === "end" ? (
                      <>
                        {/* <h3>{"step4"}</h3> */}
                        <Button type="primary" className="reg_bttn">
                          Registration Closed
                        </Button>
                      </>
                    ) : (
                      dateLoad === false &&
                      finalTime === "start" &&
                      alldata && (
                        <>
                          {/* <h3>{"step5"}</h3> */}
                          <Button
                            type="primary"
                            className="reg_bttn"
                            onClick={() => registernowclic()}
                          >
                            Register Now <FaArrowRight />
                          </Button>
                        </>
                      )
                    )}
                  </span>
                )}
                {alldata.plans && (
                  <Button
                    onClick={() => choosePlane(true)}
                    className="calender_bttn mr-3"
                  >
                    <FaRegListAlt className="topsvgicon" />
                    View plan
                  </Button>
                )}
              </div>
            </div>
          </section>
        )}

        {(allBanner.length > 0 || alldata.banner_image) && (
          <section style={{ marginTop: "-200px" }}>
            <div className="container mobileview desktop" id="">
              {allBanner.length > 1 && (
                <Swiper
                  modules={[Navigation, Pagination, A11y, Autoplay]}
                  spaceBetween={20}
                  slidesPerView={1}
                  navigation={{
                    nextEl: ".swiper-btn-next",
                    prevEl: ".swiper-btn-prev",
                  }}
                  loop={true}
                  autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                  }}
                  speed={7000}
                  pagination={{
                    el: ".swiper-pagination",
                    clickable: true,
                  }}
                  className="testimonial pb-5"
                >
                  <div className="swiper-wrapper">
                    {allBanner.length > 0 ? (
                      allBanner.map((images) => (
                        <SwiperSlide>
                          <div className="swiper-slide detailBanner">
                            <img
                              src={images.banner_url}
                              className="static-slider"
                              alt="opportunity banner"
                            />
                          </div>
                        </SwiperSlide>
                      ))
                    ) : (
                      <SwiperSlide>
                        <div className="swiper-slide detailBanner">
                          <img
                            src={alldata.banner_image}
                            className="static-slider"
                            alt="opportunity banner"
                          />
                        </div>
                      </SwiperSlide>
                    )}
                  </div>
                  <div
                    className={`swiper-buttons text-center detailslider mt-4 ${
                      allBanner.length <= 1 && "arrowhidden"
                    }`}
                  >
                    <div className="swiper-btn-prev">
                      <FaArrowLeft />
                    </div>
                    <div className="swiper-pagination dd"></div>
                    <div className="swiper-btn-next">
                      <FaArrowRight />
                    </div>
                  </div>
                </Swiper>
              )}
              {(allBanner.length === 1 || allBanner.length === 0) && (
                <Swiper
                  modules={[Navigation, Pagination, A11y, Autoplay]}
                  spaceBetween={20}
                  slidesPerView={1}
                  navigation={{
                    nextEl: ".swiper-btn-next",
                    prevEl: ".swiper-btn-prev",
                  }}
                  loop={true}
                  onSwiper={allBanner && handleAutoplay}
                  pagination={{
                    el: ".swiper-pagination",
                    clickable: true,
                  }}
                  className="testimonial pb-5"
                >
                  <div className="swiper-wrapper">
                    {allBanner.length > 0 ? (
                      allBanner.map((images) => (
                        <SwiperSlide>
                          <div className="swiper-slide detailBanner">
                            <img
                              src={images.banner_url}
                              className="static-slider"
                              alt="opportunity banner"
                            />
                          </div>
                        </SwiperSlide>
                      ))
                    ) : (
                      <SwiperSlide>
                        <div className="swiper-slide detailBanner">
                          <img
                            src={alldata.banner_image}
                            className="static-slider"
                            alt="opportunity banner"
                          />
                        </div>
                      </SwiperSlide>
                    )}
                  </div>
                  <div
                    className={`swiper-buttons text-center detailslider mt-4 ${
                      allBanner.length <= 1 && "arrowhidden"
                    }`}
                  >
                    <div className="swiper-btn-prev">
                      <FaArrowLeft />
                    </div>
                    <div className="swiper-pagination dd"></div>
                    <div className="swiper-btn-next">
                      <FaArrowRight />
                    </div>
                  </div>
                </Swiper>
              )}
            </div>
          </section>
        )}

        {showloader === true && (
          <div className="loadershow">
            <Loader/>
          </div>
        )}

        <div className="opportunity-detail mt-0" id="mainbrief">
          <nav className="nav-body">
            <div className="od-nav">
              <ul className="od-nav-links scroll-tab" id="mainNav">
                {/* <li className="active"><a href="#mainbrief" ><FaRegFileAlt />Briefs</a></li> */}
                {rounds && (
                  <li className={tabselectedval === "rounds" && "selectedtab"}>
                    <Links
                      className="Detail"
                      to="rounds"
                      spy={true}
                      // offset={-150}
                      smooth={true}
                      duration={200}
                      onClick={() => tabselected("rounds")}
                    >
                      Rounds
                    </Links>
                  </li>
                )}
                <li className={tabselectedval === "detail" && "selectedtab"}>
                  <Links
                    className="Detail"
                    to="detail"
                    spy={true}
                    offset={-150}
                    smooth={true}
                    duration={200}
                    onClick={() => tabselected("detail")}
                  >
                    Details
                  </Links>
                </li>
                {imortantdates && imortantdates.length > 0 && (
                  <li
                    className={tabselectedval === "contacts" && "selectedtab"}
                  >
                    <Links
                      className="Detail"
                      to="contacts"
                      spy={true}
                      smooth={true}
                      offset={-150}
                      duration={200}
                      onClick={() => tabselected("contacts")}
                    >
                      Contacts
                    </Links>
                  </li>
                )}
                {prizes && (
                  <li className={tabselectedval === "prizes" && "selectedtab"}>
                    <Links
                      className="Detail"
                      to="prizes"
                      spy={true}
                      offset={-100}
                      smooth={true}
                      duration={200}
                      onClick={() => tabselected("prizes")}
                    >
                      Rewards
                    </Links>
                  </li>
                )}
                <li
                  className={tabselectedval === "aboutcompny" && "selectedtab"}
                >
                  <Links
                    className="Detail"
                    to="aboutcompny"
                    spy={true}
                    offset={-100}
                    smooth={true}
                    duration={200}
                    onClick={() => tabselected("aboutcompny")}
                  >
                    About Host
                  </Links>
                </li>
              </ul>
            </div>
          </nav>

          <div className="container scroll-div" id="rounds">
            <h2 className="section-title pt-1 round-title">Rounds</h2>

            {rounds &&
              rounds.map((item, index) => (
                <Card className="content-body roundSec">
                  <span className="bigcount">0{index + 1}</span>
                  <div className="od-content-tilte flex">
                    <span className="roundCount">0{index + 1} </span>{" "}
                    <div className="flex">
                      <span className="od-content-tilte_second">
                        {item.title}{" "}
                      </span>
                      <>
                        {moment().isBetween(item.start_date, item.end_date)}
                        {new Date(item.start_date) < new Date()}
                        {new Date(item.start_date) < new Date() &&
                          RoundDateFunctionstatus(item)}
                      </>
                    </div>
                  </div>
                  <p className="od-content-text">{item.description}</p>
                  <div className="row round-btn-wrapper">
                    {
                      <>
                        <p
                          className={
                            item?.type === "quiz"
                              ? item?.isquiz === false
                                ? "col-md-11 rounddate"
                                : "col-md-8 rounddate "
                              : item.type === "submission" &&
                                  item?.submissions?.length > 0
                                ? "col-md-8 rounddate"
                                : "col-md-8 rounddate"
                          }
                        >
                          {item.start_date != null ? (
                            <React.Fragment>
                              <Timeline className="d-md-none round-timeline">
                                <Timeline.Item color="green">
                                  <strong>Start:</strong>{" "}
                                  <Moment
                                    format="Do MMM YYYY"
                                    date={item.start_date}
                                  />
                                  ,&nbsp;
                                  {item.start_time} hrs ({alldata.localTimeZone}
                                  )
                                </Timeline.Item>
                                <Timeline.Item color="red">
                                  <strong>End:</strong>{" "}
                                  <Moment
                                    format="Do MMM YYYY"
                                    date={item.end_date}
                                  />
                                  ,&nbsp;
                                  {item.end_time} hrs ({alldata.localTimeZone})
                                </Timeline.Item>
                              </Timeline>
                              <span className="d-none d-sm-block dateandtime">
                                {" "}
                                <FaRegCalendarAlt className="roundcal" />
                                <>
                                  <Moment
                                    format="Do MMM YYYY"
                                    date={item.start_date}
                                  />
                                  ,&nbsp;
                                  {item.start_time} hrs - &nbsp;
                                  <Moment
                                    format="Do MMM YYYY"
                                    date={item.end_date}
                                  />
                                  , &nbsp;
                                  {item.end_time} hrs ({alldata.localTimeZone})
                                </>
                              </span>
                            </React.Fragment>
                          ) : (
                            <>
                              <FaRegCalendarAlt className="roundcal" />
                              <span className="tbd">
                                {" "}
                                To be announced later
                              </span>
                            </>
                          )}
                        </p>
                        {item.type === "quiz" &&
                        item.isquiz === true &&
                        alldata.is_registered === true &&
                        alldata.is_leader === true &&
                        new Date(item.end_date) > new Date() ? (
                          <div className="col-md-3 alreadyplayedquiz">
                            <button className="btn quizdone  menubtn detail-download ">
                              {" "}
                              You have already played the quiz
                            </button>
                          </div>
                        ) : (
                          item.type === "quiz" &&
                          alldata.is_leader === true &&
                          alldata.is_registered === true &&
                          item.isquiz === false && (
                            <>
                              <button
                                className="btn col-md-1 menubtn quizdone detail-download"
                                onClick={(e) =>
                                  gotoquizdetail(e, alldata.slug, item.id)
                                }
                              >
                                {" "}
                                Play Quiz
                              </button>
                            </>
                          )
                        )}
                      </>
                    }
                    <React.Fragment>
                      {(alldata.external_registration ||
                        (!alldata.external_registration &&
                          alldata.is_registered)) &&
                        item.resultfile && (
                          <div className="fr casestudy">
                            <label className="btn menubtn detail-download mr-0">
                              <FaDownload />
                              &nbsp;
                              <a
                                href={item.resultfile}
                                target="_blank"
                                download
                                rel="noreferrer"
                              >
                                Results
                              </a>
                            </label>
                          </div>
                        )}
                      {localStorage.getItem("token") &&
                        alldata.is_registered === true &&
                        finaltimeforcasestudy !== "end" &&
                        moment().isBetween(
                          moment(item.start_date + " " + item.start_time),
                          moment(item.end_date + " " + item.end_time).add(
                            59,
                            "seconds"
                          ),
                          null,
                          []
                        ) &&
                        RoundDateFunction(item, index)}
                    </React.Fragment>
                  </div>
                </Card>
              ))}
          </div>
          <div className="more-about scroll-div" id="detail">
            <div className="container">
              <div className="od-content-heading">
                <h2 className="section-title">Details</h2>
              </div>
              <Card className="rp-content-body">
                <h3 className="rp-content-tilte">
                  {/* <span>Read</span> more about {alldata.organization} */}
                </h3>
                <p className="rp-content-text">
                  {/* {alldata.about} */}
                  <span dangerouslySetInnerHTML={{ __html: alldata.about }} />
                  {/* {convertToRaw(alldata.about)} */}
                </p>
              </Card>
            </div>
          </div>
          {imortantdates && imortantdates.length > 0 && (
            <section className="dnd">
              <div className="container scroll-div" id="contacts">
                <h2 className="mb-5">Milestones & Contacts</h2>
                <Row className="importantsec">
                  {imortantdates && imortantdates.length > 0 && (
                    <Col md={5} className="mb-5 contact-section">
                      <div className="row">
                        <Col md={12}>
                          <div className="dnd-body">
                            <div className="dnd-icon">
                              <img
                                className=""
                                src={
                                  require("../../detailspage/date.png").default
                                }
                                alt="od-banner"
                              />
                            </div>
                            <div className="dnd-text">
                              <div className="dnd-heading">
                                <h3>
                                  Important <span>Dates</span>
                                </h3>
                              </div>
                              {imortantdates.map((item, index) => (
                                <p className="dnd-timeslot" key={index}>
                                  {item.datetitle && (
                                    <span>{`${item.datetitle}: `}</span>
                                  )}
                                  <Moment
                                    format="Do MMM YYYY "
                                    date={item.date}
                                  />{" "}
                                </p>
                              ))}
                            </div>
                          </div>
                        </Col>
                      </div>
                    </Col>
                  )}
                  <Col md={5} className="mb-5 contact-section">
                    <div className="row">
                      {imortantcontact && (
                        <Col md={12}>
                          <div className="dnd-body">
                            <div className="dnd-icon">
                              <img
                                className=""
                                src={
                                  require("../../detailspage/review-profile.png")
                                    .default
                                }
                                alt="od-banner"
                              />
                            </div>
                            <div className="dnd-text">
                              <div className="dnd-heading">
                                <h3>
                                  Organizer <span>Contacts</span>
                                </h3>
                              </div>
                              {imortantcontact.length > 0 &&
                                imortantcontact.map((item) => (
                                  <>
                                    <p className="dnd-timeslot">
                                      {item.name && (
                                        <span>{item.name + " :"} </span>
                                      )}
                                      {item.email !== ""
                                        ? item.email
                                        : item.contact}
                                    </p>
                                    <p className="dnd-timeslot-type">
                                      {item.email !== "" ? item.contact : ""}
                                    </p>
                                  </>
                                ))}
                              {imortantcontact.length === 0 && (
                                <>
                                  <br />
                                  <p className="dnd-timeslot-type">
                                    Organizer contact not available
                                  </p>
                                </>
                              )}
                            </div>
                          </div>
                        </Col>
                      )}
                    </div>
                  </Col>
                  {alldata.rulebook && (
                    <Col md={4} className="mb-5">
                      <div className="row">
                        <Col md={12}>
                          <div className="dnd-body">
                            <div className="dnd-icon">
                              <img
                                className="list-icon-img"
                                src={
                                  require("../../detailspage/Icon-Document.png")
                                    .default
                                }
                                alt="od-banner"
                              />
                            </div>
                            <div className="dnd-text">
                              <div className="dnd-heading">
                                <h3>
                                  Organizer <span>Documents</span>
                                </h3>
                              </div>
                              <button className="btn">
                                <i className="fa fa-file"></i>{" "}
                                <a href={alldata.rulebook} download>
                                  {" "}
                                  Competition Brief
                                </a>
                              </button>
                            </div>
                          </div>
                        </Col>
                      </div>
                    </Col>
                  )}
                </Row>
              </div>
            </section>
          )}
          {prizes && (
            <section className="rewards-prizes scroll-div" id="prizes">
              <div className="container">
                <h2 className="section-title">Rewards</h2>

                {!alldata.slug === "HCCB-GET-44" && (
                  <div className="rp-heading">
                    <h3>Rewards and Recognition!</h3>
                  </div>
                )}
                {/* <div className="rp-text">
                  <p>Attractive prizes to be won along with PPIs.</p> should
            be dynamic
                </div> */}
                <div className="rp-card-body mt-4">
                  <Row>
                    {prizes &&
                      prizes.map((item, priceindex) => (
                        <Col md={4}>
                          <div className="text-left prize-sec">
                            {item.certificate_provided != "0" && (
                              <p className="certificatebookmark timeshow68">
                                Certificate
                              </p>
                            )}
                            {priceindex === 0 ? (
                              <img
                                className="priceimg"
                                src={
                                  require("../../price/first_price.png").default
                                }
                                alt="od-banner"
                              />
                            ) : priceindex === 1 ? (
                              <img
                                className="priceimg"
                                src={
                                  require("../../price/second_price.png")
                                    .default
                                }
                                alt="od-banner"
                              />
                            ) : priceindex === 2 ? (
                              <img
                                className="priceimg"
                                src={
                                  require("../../price/third_price.png").default
                                }
                                alt="od-banner"
                              />
                            ) : (
                              priceindex >= 3 && (
                                <img
                                  className="priceimg"
                                  src={require("../../price/Rest.png").default}
                                  alt="od-banner"
                                />
                              )
                            )}
                            <div className="">
                              <div className="dnd-heading">
                                <h3 className="rewardrank">{item.rank}</h3>
                              </div>

                              <br />
                              {alldata.slug === "HCCB-Disrupt-Season-1-38" ? (
                                <p>
                                  Pre-Placement Interviews (PPIs) opportunities{" "}
                                  <br />
                                  Chance to interact with HCCB Senior Leadership
                                  Team <br />
                                  Certificate of Appreciation
                                </p>
                              ) : alldata.title === "Consulting Symposium" ||
                                alldata.title === "Marketing Symposium" ? (
                                <p className="pricedec">
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: item.description,
                                    }}
                                  />
                                </p>
                              ) : alldata.title ===
                                "HCCB Learners Premier League Quiz 2021" ? (
                                <p className="pricedec">
                                  {" "}
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: item.description,
                                    }}
                                  />
                                </p>
                              ) : alldata.title ===
                                "TATA Steel TomorrowLab 2022 (Idea Track)" ? (
                                <>
                                  {" "}
                                  <p className="pricedec">
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: item.description,
                                      }}
                                    />{" "}
                                    <br />
                                    Certificate of Achievement
                                  </p>
                                </>
                              ) : (
                                <>
                                  <p className="pricedec">
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: item.description,
                                      }}
                                    />
                                    <br />
                                    {alldata.title ===
                                      "Cipla Ascend Season 1" ||
                                      (alldata.title ===
                                        "TATA Steel TomorrowLab 2022 (Design Track)" &&
                                        "Certificate of Achievement")}
                                  </p>
                                </>
                              )}
                              <h3 className="prize_money">
                                {item.prize_money ? (
                                  <>
                                    {item.currency === "doller" ? (
                                      <i className="fa fa-dollar"></i>
                                    ) : (
                                      <i className="fa fa-rupee"></i>
                                    )}
                                    {new Intl.NumberFormat("en-IN").format(
                                      item.prize_money.toLocaleString()
                                    )}{" "}
                                  </>
                                ) : (
                                  ""
                                )}
                              </h3>
                            </div>
                          </div>
                        </Col>
                      ))}
                  </Row>
                </div>
              </div>
            </section>
          )}
          {alldata.slug === "" && (
            <section className="rewards-prizes scroll-div" id="prizes">
              <div className="container">
                <h2 className="section-title">Rewards</h2>

                {!alldata.slug === "HCCB-GET-44" && (
                  <div className="rp-heading">
                    <h3>Rewards and Recognition!</h3>
                  </div>
                )}
                <div className="rp-text">
                  {/* <p>Attractive prizes to be won along with PPIs.</p> should
              be dynamic */}
                </div>
                <div className="rp-card-body">
                  <Row></Row>
                </div>
              </div>
            </section>
          )}
          <section className="conent-body review scroll-div" id="aboutcompny">
            {alldata.about_company && (
              <>
                <h2 class="section-title">About <span>{alldata.organization}</span></h2>
                <div className="container mt-4">
                  {/* <h2>
                    About <span>{alldata.organization}</span>
                  </h2> */}
                  <p className="od-content-text aboutcompny">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: alldata.about_company,
                      }}
                    />
                  </p>
                </div>
              </>
            )}
          </section>
        </div>
        {/* modal show */}
      </div>
      <span className="mainhomepage">
        <Footer />
      </span>

      {/* Submission modal show */}
      {renderDynamicSubmissionUserModal()}
    </React.Fragment>
  );
}

export default OpportunityDetail;
