import React, { useEffect, useState } from "react";
import Toggle from "react-toggle";

function ProfessionalsOptions({
  error,
  professionalOptions,
  onProfessionalOptionsChange,
}) {
  const [isOpenToAll, setIsOpenToAll] = useState(
    professionalOptions.isOpenToAll
  );
  const [experienceMin, setExperienceMin] = useState(
    professionalOptions.experienceMin
  );
  const [experienceMax, setExperienceMax] = useState(
    professionalOptions.experienceMax
  );
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (errors.length > 0) {
      error("Add", "Professionals");
    } else {
      error("Remove", "Professionals");
    }
    // eslint-disable-next-line
  }, [errors]);

  useEffect(() => {
    if (
      professionalOptions.isOpenToAll !== isOpenToAll ||
      professionalOptions.experienceMin !== experienceMin ||
      professionalOptions.experienceMax !== experienceMax
    ) {
      onProfessionalOptionsChange({
        isOpenToAll,
        experienceMin,
        experienceMax,
      });
    }
    // eslint-disable-next-line
  }, [isOpenToAll, experienceMin, experienceMax]);

  const handleExperienceChange = (type, value) => {
    setErrors([]);

    const numValue = value === "" ? "" : Number(value); // Handle empty input

    // Update the state first, even for invalid inputs
    if (type === "min") {
      setExperienceMin(numValue);
    } else if (type === "max") {
      setExperienceMax(numValue);
    }

    // Handle validation
    setErrors((prevErrors) => {
      const errorField = `experience${type.charAt(0).toUpperCase() + type.slice(1)}`;
      const newErrors = [...prevErrors];

      // Add error if value is invalid or violates constraints
      if (
        numValue === "" ||
        (type === "min" && (numValue > experienceMax || numValue < 0)) ||
        (type === "max" && (numValue < experienceMin || numValue < 0))
      ) {
        if (!newErrors.includes(errorField)) {
          newErrors.push(errorField);
        }
      } else {
        // Remove error if the value becomes valid
        return newErrors.filter((error) => error !== errorField);
      }

      return newErrors;
    });
  };

  return (
    <div className="professional-options-container">
      <div className="professional-open-to-all-div">
        <p>Open to all</p>
        <Toggle
          checked={isOpenToAll}
          onChange={() => setIsOpenToAll(!isOpenToAll)}
        />
      </div>

      {!isOpenToAll && (
        <div className="professional-experience-div">
          <p>Experience Required (In Years)</p>
          <span className="experience-input-container">
            <span
              className={`experience-input-container-item ${errors.includes("experienceMin") ? "error" : ""}`}
            >
              <label htmlFor="minimum experience">Min</label>
              <input
                type="number"
                min="0"
                max="10"
                value={experienceMin}
                onChange={(e) => handleExperienceChange("min", e.target.value)}
              />
            </span>
            <span
              className={`experience-input-container-item ${errors.includes("experienceMax") ? "error" : ""}`}
            >
              <label htmlFor="maximum experience">Max</label>
              <input
                type="number"
                min="0"
                max="10"
                value={experienceMax}
                onChange={(e) => handleExperienceChange("max", e.target.value)}
              />
            </span>
          </span>
        </div>
      )}
    </div>
  );
}

export default ProfessionalsOptions;
