import React from "react";
import { getYearLabel, isEqual } from "../../../CommonFiles/Utils/ObjectUtils";

function CollegeStudentsOptions({
  CollegeStudentsFilters,
  collegeStudentsOptions,
  onCollegeStudentsOptionsChange,
}) {
  const domains = CollegeStudentsFilters.map((item) => {
    return item.domain;
  });
  const [activeTab, setActiveTab] = React.useState(domains[0]);
  const [selectedCourse, setSelectedCourse] = React.useState("All");
  const [configs, setConfigs] = React.useState(collegeStudentsOptions);

  React.useEffect(() => {
    if (!isEqual(configs, collegeStudentsOptions)) {
      onCollegeStudentsOptionsChange(configs);
    }
    // eslint-disable-next-line
  }, [configs]);

  const handleOptionClick = (e, courseName) => {
    if (e.target.checked) {
      setConfigs((prev) => {
        return prev.map((item) => {
          if (item.domain === activeTab) {
            return {
              ...item,
              courseNames: [...item.courseNames, courseName],
            };
          } else {
            return item;
          }
        });
      });
    } else {
      setConfigs((prev) => {
        return prev.map((item) => {
          if (item.domain === activeTab) {
            return {
              ...item,
              courseNames: item.courseNames.filter(
                (cn) => cn.courseName !== courseName.courseName
              ),
            };
          } else {
            return item;
          }
        });
      });
    }
  };

  const handleAllClick = (e) => {
    if (e.target.checked) {
      const updatedConfigs = configs.map((item) => {
        if (item.domain === activeTab) {
          return {
            ...item,
            courseNames: [
              ...CollegeStudentsFilters.find(
                (item) => item.domain === activeTab
              ).courseNames,
            ],
          };
        }
        return item;
      });

      setConfigs(updatedConfigs);
    } else {
      const updatedConfigs = configs.map((item) => {
        if (item.domain === activeTab) {
          return {
            ...item,
            courseNames: [],
          };
        }
        return item;
      });

      setConfigs(updatedConfigs);
    }
  };

  const isCourseSelected = (courseName) => {
    if (courseName === "All") {
      return (
        CollegeStudentsFilters.find((item) => item.domain === activeTab)
          .courseNames.length ===
        configs.find((item) => item.domain === activeTab).courseNames.length
      );
    } else {
      const selectedCourse = configs
        .find((item) => item.domain === activeTab)
        .courseNames.find((item) => item.courseName === courseName);
      return selectedCourse !== undefined;
    }
  };

  const isSpecializationSelected = (specialisation) => {
    if (selectedCourse === "All" || !specialisation) return false;

    if (specialisation === "All" && selectedCourse !== "All") {
      return (
        CollegeStudentsFilters.find(
          (item) => item.domain === activeTab
        ).courseNames.find((item) => item.courseName === selectedCourse)
          .specializationNames.length ===
        configs
          .find((item) => item.domain === activeTab)
          .courseNames.find((item) => item.courseName === selectedCourse)
          ?.specializationNames?.length
      );
    } else {
      return configs
        .find((item) => item.domain === activeTab)
        .courseNames.find((item) => item.courseName === selectedCourse)
        ?.specializationNames?.find(
          (item) => item.specializationName === specialisation
        );
    }
  };

  const handleSpecializationClick = (specialisation) => {
    if (specialisation === "All") {
      if (!isSpecializationSelected("All")) {
        // When All is unselected
        setConfigs((prev) => {
          return prev.map((item) => {
            if (item.domain === activeTab) {
              if (
                item.courseNames.find(
                  (item) => item.courseName === selectedCourse
                )
              ) {
                return {
                  ...item,
                  courseNames: item.courseNames.map((cn) => {
                    if (cn.courseName === selectedCourse) {
                      return {
                        ...cn,
                        specializationNames: [
                          ...CollegeStudentsFilters.find(
                            (item) => item.domain === activeTab
                          ).courseNames.find(
                            (item) => item.courseName === selectedCourse
                          ).specializationNames,
                        ],
                      };
                    } else {
                      return cn;
                    }
                  }),
                };
              } else {
                return {
                  ...item,
                  courseNames: [
                    ...item.courseNames,
                    CollegeStudentsFilters.find(
                      (item) => item.domain === activeTab
                    ).courseNames.find(
                      (item) => item.courseName === selectedCourse
                    ),
                  ],
                };
              }
            } else {
              return item;
            }
          });
        });
      }
    } else {
      if (
        !isSpecializationSelected("All") &&
        !isSpecializationSelected(specialisation.specializationName)
      ) {
        // When selecting a specialization and All is not selected
        setConfigs((prev) => {
          return prev.map((item) => {
            if (item.domain === activeTab) {
              if (
                item.courseNames.find(
                  (item) => item.courseName === selectedCourse
                )
              ) {
                return {
                  ...item,
                  courseNames: item.courseNames.map((deg) => {
                    if (deg.courseName === selectedCourse) {
                      return {
                        ...deg,
                        specializationNames: [
                          ...deg.specializationNames,
                          specialisation,
                        ],
                      };
                    } else {
                      return deg;
                    }
                  }),
                };
              } else {
                return {
                  ...item,
                  courseNames: [
                    ...item.courseNames,
                    {
                      ...CollegeStudentsFilters.find(
                        (item) => item.domain === activeTab
                      ).courseNames.find(
                        (item) => item.courseName === selectedCourse
                      ),
                      specializationNames: [specialisation],
                    },
                  ],
                };
              }
            } else {
              return item;
            }
          });
        });
      } else if (
        isSpecializationSelected("All") &&
        isSpecializationSelected(specialisation.specializationName)
      ) {
        // When selecting a specialization and All is also selected
        setConfigs((prev) => {
          return prev.map((item) => {
            if (item.domain === activeTab) {
              if (
                item.courseNames.find(
                  (item) => item.courseName === selectedCourse
                )
              ) {
                return {
                  ...item,
                  courseNames: item.courseNames.map((deg) => {
                    if (deg.courseName === selectedCourse) {
                      return {
                        ...deg,
                        specializationNames: [
                          ...deg.specializationNames.filter(
                            (item) =>
                              item.specializationName ===
                              specialisation.specializationName
                          ),
                        ],
                      };
                    } else {
                      return deg;
                    }
                  }),
                };
              } else {
                // When selecting a specialization but the courseName is not selected
                return {
                  ...item,
                  courseNames: [
                    ...item.courseNames,
                    {
                      ...CollegeStudentsFilters.find(
                        (item) => item.domain === activeTab
                      ).courseNames.find(
                        (item) => item.courseName === selectedCourse
                      ),
                      specializationNames: [specialisation],
                    },
                  ],
                };
              }
            } else {
              return item;
            }
          });
        });
      } else {
        // When deselecting a specialization and All is not selected
        setConfigs((prev) => {
          return prev.map((item) => {
            if (item.domain === activeTab) {
              return {
                ...item,
                courseNames: item.courseNames.map((deg) => {
                  if (deg.courseName === selectedCourse) {
                    return {
                      ...deg,
                      specializationNames:
                        deg.specializationNames.length > 1
                          ? deg.specializationNames.filter(
                              (item) =>
                                item.specializationName !==
                                specialisation.specializationName
                            )
                          : CollegeStudentsFilters.find(
                              (item) => item.domain === activeTab
                            ).courseNames.find(
                              (item) => item.courseName === selectedCourse
                            ).specializationNames,
                    };
                  } else {
                    return deg;
                  }
                }),
              };
            } else {
              return item;
            }
          });
        });
      }
    }
  };

  const getAllSpecializations = () => {
    if (selectedCourse === "All") return [];

    return CollegeStudentsFilters.find(
      (item) => item.domain === activeTab
    ).courseNames.find((item) => item.courseName === selectedCourse)
      .specializationNames;
  };

  const getAllProgrammes = () => {
    if (selectedCourse === "All") return [];

    return CollegeStudentsFilters.find(
      (item) => item.domain === activeTab
    ).courseNames.find((item) => item.courseName === selectedCourse)
      .programmeNames;
  };

  const getAllCourseDurations = () => {
    if (selectedCourse === "All") return [];

    return CollegeStudentsFilters.find((item) => item.domain === activeTab)
      .courseNames.find((item) => item.courseName === selectedCourse)
      .courseDurations.sort((a, b) => a - b);
  };

  const getAllYearOfStudies = () => {
    if (selectedCourse === "All") return [];

    const selectedCourseDurations = configs
      .find((item) => item.domain === activeTab)
      .courseNames.find(
        (item) => item.courseName === selectedCourse
      )?.courseDurations;

    if (!selectedCourseDurations || selectedCourseDurations.length === 0)
      return [];

    return CollegeStudentsFilters.find((item) => item.domain === activeTab)
      .courseNames.find((item) => item.courseName === selectedCourse)
      .yearOfStudy.sort((a, b) => a - b)
      .filter(
        (item) => item <= Math.ceil(Math.max(...selectedCourseDurations) / 12)
      );
  };

  const isProgrammeSelected = (programmeName) => {
    if (programmeName === "All") {
      return (
        CollegeStudentsFilters.find(
          (item) => item.domain === activeTab
        ).courseNames.find((item) => item.courseName === selectedCourse)
          .programmeNames.length ===
        configs
          .find((item) => item.domain === activeTab)
          .courseNames.find((item) => item.courseName === selectedCourse)
          ?.programmeNames?.length
      );
    } else {
      const selectedProgramme = configs
        .find((item) => item.domain === activeTab)
        .courseNames.find((item) => item.courseName === selectedCourse)
        ?.programmeNames?.find((item) => item === programmeName);
      return selectedProgramme !== undefined;
    }
  };

  const isCourseDurationSelected = (courseDuration) => {
    if (courseDuration === "All") {
      return (
        CollegeStudentsFilters.find(
          (item) => item.domain === activeTab
        ).courseNames.find((item) => item.courseName === selectedCourse)
          .courseDurations.length ===
        configs
          .find((item) => item.domain === activeTab)
          .courseNames.find((item) => item.courseName === selectedCourse)
          ?.courseDurations?.length
      );
    } else {
      const selectedCourseDuration = configs
        .find((item) => item.domain === activeTab)
        .courseNames.find((item) => item.courseName === selectedCourse)
        ?.courseDurations?.find((item) => item === courseDuration);
      return selectedCourseDuration !== undefined;
    }
  };

  const isYearOfStudySelected = (year) => {
    if (year === "All") {
      return (
        CollegeStudentsFilters.find(
          (item) => item.domain === activeTab
        ).courseNames.find((item) => item.courseName === selectedCourse)
          .yearOfStudy.length ===
        configs
          .find((item) => item.domain === activeTab)
          .courseNames.find((item) => item.courseName === selectedCourse)
          ?.yearOfStudy?.length
      );
    } else {
      const selectedYearOfStudy = configs
        .find((item) => item.domain === activeTab)
        .courseNames.find((item) => item.courseName === selectedCourse)
        ?.yearOfStudy?.find((item) => item === year);
      return selectedYearOfStudy !== undefined;
    }
  };

  const getAllPassoutYears = () => {
    if (selectedCourse === "All") return [];

    return CollegeStudentsFilters.find(
      (item) => item.domain === activeTab
    ).courseNames.find((item) => item.courseName === selectedCourse)
      .passoutYears;
  };

  const isPassoutYearSelected = (passoutYear) => {
    if (passoutYear === "All") {
      return (
        CollegeStudentsFilters.find(
          (item) => item.domain === activeTab
        ).courseNames.find((item) => item.courseName === selectedCourse)
          .passoutYears.length ===
        configs
          .find((item) => item.domain === activeTab)
          .courseNames.find((item) => item.courseName === selectedCourse)
          ?.passoutYears?.length
      );
    } else {
      const selectedPassoutYear = configs
        .find((item) => item.domain === activeTab)
        .courseNames.find((item) => item.courseName === selectedCourse)
        ?.passoutYears?.find((item) => item === passoutYear);
      return selectedPassoutYear !== undefined;
    }
  };

  const handlePassoutYearSelect = (passoutYear) => {
    if (passoutYear === "All") {
      if (!isPassoutYearSelected("All")) {
        // When All is unselected
        setConfigs((prev) => {
          return prev.map((item) => {
            if (item.domain === activeTab) {
              if (
                item.courseNames.find(
                  (item) => item.courseName === selectedCourse
                )
              ) {
                return {
                  ...item,
                  courseNames: item.courseNames.map((cn) => {
                    if (cn.courseName === selectedCourse) {
                      return {
                        ...cn,
                        passoutYears: [
                          ...CollegeStudentsFilters.find(
                            (item) => item.domain === activeTab
                          ).courseNames.find(
                            (item) => item.courseName === selectedCourse
                          ).passoutYears,
                        ],
                      };
                    } else {
                      return cn;
                    }
                  }),
                };
              } else {
                return {
                  ...item,
                  courseNames: [
                    ...item.courseNames,
                    CollegeStudentsFilters.find(
                      (item) => item.domain === activeTab
                    ).courseNames.find(
                      (item) => item.courseName === selectedCourse
                    ),
                  ],
                };
              }
            } else {
              return item;
            }
          });
        });
      }
    } else {
      if (
        !isPassoutYearSelected("All") &&
        !isPassoutYearSelected(passoutYear)
      ) {
        // When selecting a passout year and All is not selected
        setConfigs((prev) => {
          return prev.map((item) => {
            if (item.domain === activeTab) {
              if (
                item.courseNames.find(
                  (item) => item.courseName === selectedCourse
                )
              ) {
                return {
                  ...item,
                  courseNames: item.courseNames.map((deg) => {
                    if (deg.courseName === selectedCourse) {
                      return {
                        ...deg,
                        passoutYears: [...deg.passoutYears, passoutYear],
                      };
                    } else {
                      return deg;
                    }
                  }),
                };
              } else {
                return {
                  ...item,
                  courseNames: [
                    ...item.courseNames,
                    {
                      ...CollegeStudentsFilters.find(
                        (item) => item.domain === activeTab
                      ).courseNames.find(
                        (item) => item.courseName === selectedCourse
                      ),
                      passoutYears: [passoutYear],
                    },
                  ],
                };
              }
            } else {
              return item;
            }
          });
        });
      } else if (
        isPassoutYearSelected("All") &&
        isPassoutYearSelected(passoutYear)
      ) {
        // When selecting a passout year and All is also selected
        setConfigs((prev) => {
          return prev.map((item) => {
            if (item.domain === activeTab) {
              if (
                item.courseNames.find(
                  (item) => item.courseName === selectedCourse
                )
              ) {
                return {
                  ...item,
                  courseNames: item.courseNames.map((deg) => {
                    if (deg.courseName === selectedCourse) {
                      return {
                        ...deg,
                        passoutYears: [
                          ...deg.passoutYears.filter(
                            (item) => item === passoutYear
                          ),
                        ],
                      };
                    } else {
                      return deg;
                    }
                  }),
                };
              } else {
                // When selecting a passout year but the courseName is not selected
                return {
                  ...item,
                  courseNames: [
                    ...item.courseNames,
                    {
                      ...CollegeStudentsFilters.find(
                        (item) => item.domain === activeTab
                      ).courseNames.find(
                        (item) => item.courseName === selectedCourse
                      ),
                      passoutYears: [passoutYear],
                    },
                  ],
                };
              }
            } else {
              return item;
            }
          });
        });
      } else {
        // When deselecting a passout year and All is not selected
        setConfigs((prev) => {
          return prev.map((item) => {
            if (item.domain === activeTab) {
              return {
                ...item,
                courseNames: item.courseNames.map((deg) => {
                  if (deg.courseName === selectedCourse) {
                    return {
                      ...deg,
                      passoutYears:
                        deg.passoutYears.length > 1
                          ? deg.passoutYears.filter(
                              (item) => item !== passoutYear
                            )
                          : CollegeStudentsFilters.find(
                              (item) => item.domain === activeTab
                            ).courseNames.find(
                              (item) => item.courseName === selectedCourse
                            ).passoutYears,
                    };
                  } else {
                    return deg;
                  }
                }),
              };
            } else {
              return item;
            }
          });
        });
      }
    }
  };

  const handleProgrammeSelect = (programmeName) => {
    if (programmeName === "All") {
      if (!isProgrammeSelected("All")) {
        // When All is unselected
        setConfigs((prev) => {
          return prev.map((item) => {
            if (item.domain === activeTab) {
              if (
                item.courseNames.find(
                  (item) => item.courseName === selectedCourse
                )
              ) {
                return {
                  ...item,
                  courseNames: item.courseNames.map((cn) => {
                    if (cn.courseName === selectedCourse) {
                      return {
                        ...cn,
                        programmeNames: [
                          ...CollegeStudentsFilters.find(
                            (item) => item.domain === activeTab
                          ).courseNames.find(
                            (item) => item.courseName === selectedCourse
                          ).programmeNames,
                        ],
                      };
                    } else {
                      return cn;
                    }
                  }),
                };
              } else {
                return {
                  ...item,
                  courseNames: [
                    ...item.courseNames,
                    CollegeStudentsFilters.find(
                      (item) => item.domain === activeTab
                    ).courseNames.find(
                      (item) => item.courseName === selectedCourse
                    ),
                  ],
                };
              }
            } else {
              return item;
            }
          });
        });
      }
    } else {
      if (!isProgrammeSelected("All") && !isProgrammeSelected(programmeName)) {
        // When selecting a programme and All is not selected
        setConfigs((prev) => {
          return prev.map((item) => {
            if (item.domain === activeTab) {
              if (
                item.courseNames.find(
                  (item) => item.courseName === selectedCourse
                )
              ) {
                return {
                  ...item,
                  courseNames: item.courseNames.map((deg) => {
                    if (deg.courseName === selectedCourse) {
                      return {
                        ...deg,
                        programmeNames: [...deg.programmeNames, programmeName],
                      };
                    } else {
                      return deg;
                    }
                  }),
                };
              } else {
                return {
                  ...item,
                  courseNames: [
                    ...item.courseNames,
                    {
                      ...CollegeStudentsFilters.find(
                        (item) => item.domain === activeTab
                      ).courseNames.find(
                        (item) => item.courseName === selectedCourse
                      ),
                      programmeNames: [programmeName],
                    },
                  ],
                };
              }
            } else {
              return item;
            }
          });
        });
      } else if (
        isProgrammeSelected("All") &&
        isProgrammeSelected(programmeName)
      ) {
        // When selecting a programme and All is also selected
        setConfigs((prev) => {
          return prev.map((item) => {
            if (item.domain === activeTab) {
              if (
                item.courseNames.find(
                  (item) => item.courseName === selectedCourse
                )
              ) {
                return {
                  ...item,
                  courseNames: item.courseNames.map((deg) => {
                    if (deg.courseName === selectedCourse) {
                      return {
                        ...deg,
                        programmeNames: [
                          ...deg.programmeNames.filter(
                            (item) => item === programmeName
                          ),
                        ],
                      };
                    } else {
                      return deg;
                    }
                  }),
                };
              } else {
                // When selecting a programme but the courseName is not selected
                return {
                  ...item,
                  courseNames: [
                    ...item.courseNames,
                    {
                      ...CollegeStudentsFilters.find(
                        (item) => item.domain === activeTab
                      ).courseNames.find(
                        (item) => item.courseName === selectedCourse
                      ),
                      programmeNames: [programmeName],
                    },
                  ],
                };
              }
            } else {
              return item;
            }
          });
        });
      } else {
        // When deselecting a programme and All is not selected
        setConfigs((prev) => {
          return prev.map((item) => {
            if (item.domain === activeTab) {
              return {
                ...item,
                courseNames: item.courseNames.map((deg) => {
                  if (deg.courseName === selectedCourse) {
                    return {
                      ...deg,
                      programmeNames:
                        deg.programmeNames.length > 1
                          ? deg.programmeNames.filter(
                              (item) => item !== programmeName
                            )
                          : CollegeStudentsFilters.find(
                              (item) => item.domain === activeTab
                            ).courseNames.find(
                              (item) => item.courseName === selectedCourse
                            ).programmeNames,
                    };
                  } else {
                    return deg;
                  }
                }),
              };
            } else {
              return item;
            }
          });
        });
      }
    }
  };

  const handleCourseDurationSelect = (courseDuration) => {
    if (courseDuration === "All") {
      if (!isCourseDurationSelected("All")) {
        // When All is unselected
        setConfigs((prev) => {
          return prev.map((item) => {
            if (item.domain === activeTab) {
              if (
                item.courseNames.find(
                  (item) => item.courseName === selectedCourse
                )
              ) {
                return {
                  ...item,
                  courseNames: item.courseNames.map((cn) => {
                    if (cn.courseName === selectedCourse) {
                      return {
                        ...cn,
                        courseDurations: [
                          ...CollegeStudentsFilters.find(
                            (item) => item.domain === activeTab
                          ).courseNames.find(
                            (item) => item.courseName === selectedCourse
                          ).courseDurations,
                        ],
                      };
                    } else {
                      return cn;
                    }
                  }),
                };
              } else {
                return {
                  ...item,
                  courseNames: [
                    ...item.courseNames,
                    CollegeStudentsFilters.find(
                      (item) => item.domain === activeTab
                    ).courseNames.find(
                      (item) => item.courseName === selectedCourse
                    ),
                  ],
                };
              }
            } else {
              return item;
            }
          });
        });
      }
    } else {
      if (
        !isCourseDurationSelected("All") &&
        !isCourseDurationSelected(courseDuration)
      ) {
        // When selecting a passout year and All is not selected
        setConfigs((prev) => {
          return prev.map((item) => {
            if (item.domain === activeTab) {
              if (
                item.courseNames.find(
                  (item) => item.courseName === selectedCourse
                )
              ) {
                return {
                  ...item,
                  courseNames: item.courseNames.map((deg) => {
                    if (deg.courseName === selectedCourse) {
                      return {
                        ...deg,
                        courseDurations: [
                          ...deg.courseDurations,
                          courseDuration,
                        ],
                      };
                    } else {
                      return deg;
                    }
                  }),
                };
              } else {
                return {
                  ...item,
                  courseNames: [
                    ...item.courseNames,
                    {
                      ...CollegeStudentsFilters.find(
                        (item) => item.domain === activeTab
                      ).courseNames.find(
                        (item) => item.courseName === selectedCourse
                      ),
                      courseDurations: [courseDuration],
                    },
                  ],
                };
              }
            } else {
              return item;
            }
          });
        });
      } else if (
        isCourseDurationSelected("All") &&
        isCourseDurationSelected(courseDuration)
      ) {
        // When selecting a passout year and All is also selected
        setConfigs((prev) => {
          return prev.map((item) => {
            if (item.domain === activeTab) {
              if (
                item.courseNames.find(
                  (item) => item.courseName === selectedCourse
                )
              ) {
                return {
                  ...item,
                  courseNames: item.courseNames.map((deg) => {
                    if (deg.courseName === selectedCourse) {
                      return {
                        ...deg,
                        courseDurations: [
                          ...deg.courseDurations.filter(
                            (item) => item === courseDuration
                          ),
                        ],
                      };
                    } else {
                      return deg;
                    }
                  }),
                };
              } else {
                // When selecting a passout year but the courseName is not selected
                return {
                  ...item,
                  courseNames: [
                    ...item.courseNames,
                    {
                      ...CollegeStudentsFilters.find(
                        (item) => item.domain === activeTab
                      ).courseNames.find(
                        (item) => item.courseName === selectedCourse
                      ),
                      courseDurations: [courseDuration],
                    },
                  ],
                };
              }
            } else {
              return item;
            }
          });
        });
      } else {
        // When deselecting a passout year and All is not selected
        setConfigs((prev) => {
          return prev.map((item) => {
            if (item.domain === activeTab) {
              return {
                ...item,
                courseNames: item.courseNames.map((deg) => {
                  if (deg.courseName === selectedCourse) {
                    return {
                      ...deg,
                      courseDurations:
                        deg.courseDurations.length > 1
                          ? deg.courseDurations.filter(
                              (item) => item !== courseDuration
                            )
                          : CollegeStudentsFilters.find(
                              (item) => item.domain === activeTab
                            ).courseNames.find(
                              (item) => item.courseName === selectedCourse
                            ).courseDurations,
                    };
                  } else {
                    return deg;
                  }
                }),
              };
            } else {
              return item;
            }
          });
        });
      }
    }
  };

  const handleYearOfStudySelect = (year) => {
    if (year === "All") {
      if (!isYearOfStudySelected("All")) {
        // When All is unselected
        setConfigs((prev) => {
          return prev.map((item) => {
            if (item.domain === activeTab) {
              if (
                item.courseNames.find(
                  (item) => item.courseName === selectedCourse
                )
              ) {
                return {
                  ...item,
                  courseNames: item.courseNames.map((cn) => {
                    if (cn.courseName === selectedCourse) {
                      return {
                        ...cn,
                        yearOfStudy: [
                          ...CollegeStudentsFilters.find(
                            (item) => item.domain === activeTab
                          ).courseNames.find(
                            (item) => item.courseName === selectedCourse
                          ).yearOfStudy,
                        ],
                      };
                    } else {
                      return cn;
                    }
                  }),
                };
              } else {
                return {
                  ...item,
                  courseNames: [
                    ...item.courseNames,
                    CollegeStudentsFilters.find(
                      (item) => item.domain === activeTab
                    ).courseNames.find(
                      (item) => item.courseName === selectedCourse
                    ),
                  ],
                };
              }
            } else {
              return item;
            }
          });
        });
      }
    } else {
      if (!isYearOfStudySelected("All") && !isYearOfStudySelected(year)) {
        // When selecting a passout year and All is not selected
        setConfigs((prev) => {
          return prev.map((item) => {
            if (item.domain === activeTab) {
              if (
                item.courseNames.find(
                  (item) => item.courseName === selectedCourse
                )
              ) {
                return {
                  ...item,
                  courseNames: item.courseNames.map((deg) => {
                    if (deg.courseName === selectedCourse) {
                      return {
                        ...deg,
                        yearOfStudy: [...deg.yearOfStudy, year],
                      };
                    } else {
                      return deg;
                    }
                  }),
                };
              } else {
                return {
                  ...item,
                  courseNames: [
                    ...item.courseNames,
                    {
                      ...CollegeStudentsFilters.find(
                        (item) => item.domain === activeTab
                      ).courseNames.find(
                        (item) => item.courseName === selectedCourse
                      ),
                      yearOfStudy: [year],
                    },
                  ],
                };
              }
            } else {
              return item;
            }
          });
        });
      } else if (isYearOfStudySelected("All") && isYearOfStudySelected(year)) {
        // When selecting a passout year and All is also selected
        setConfigs((prev) => {
          return prev.map((item) => {
            if (item.domain === activeTab) {
              if (
                item.courseNames.find(
                  (item) => item.courseName === selectedCourse
                )
              ) {
                return {
                  ...item,
                  courseNames: item.courseNames.map((deg) => {
                    if (deg.courseName === selectedCourse) {
                      return {
                        ...deg,
                        yearOfStudy: [
                          ...deg.yearOfStudy.filter((item) => item === year),
                        ],
                      };
                    } else {
                      return deg;
                    }
                  }),
                };
              } else {
                // When selecting a passout year but the courseName is not selected
                return {
                  ...item,
                  courseNames: [
                    ...item.courseNames,
                    {
                      ...CollegeStudentsFilters.find(
                        (item) => item.domain === activeTab
                      ).courseNames.find(
                        (item) => item.courseName === selectedCourse
                      ),
                      yearOfStudy: [year],
                    },
                  ],
                };
              }
            } else {
              return item;
            }
          });
        });
      } else {
        // When deselecting a passout year and All is not selected
        setConfigs((prev) => {
          return prev.map((item) => {
            if (item.domain === activeTab) {
              return {
                ...item,
                courseNames: item.courseNames.map((deg) => {
                  if (deg.courseName === selectedCourse) {
                    return {
                      ...deg,
                      yearOfStudy:
                        deg.yearOfStudy.length > 1
                          ? deg.yearOfStudy.filter((item) => item !== year)
                          : CollegeStudentsFilters.find(
                              (item) => item.domain === activeTab
                            ).courseNames.find(
                              (item) => item.courseName === selectedCourse
                            ).yearOfStudy,
                    };
                  } else {
                    return deg;
                  }
                }),
              };
            } else {
              return item;
            }
          });
        });
      }
    }
  };

  return (
    <div className="college-students-options-container">
      <div className="courses-tabs">
        {domains.map((item, index) => {
          return (
            <div
              key={index}
              className={`tab ${activeTab === item ? "active" : ""}`}
              onClick={() => {
                setActiveTab(item);
                item !== "Others"
                  ? setSelectedCourse("All")
                  : setSelectedCourse("Others");
              }}
            >
              {item}
            </div>
          );
        })}
      </div>

      <div className="tab-content">
        <div className="stream-list">
          {activeTab !== "Others" && (
            <div
              className={`stream-item ${
                isCourseSelected("All") ? "selected" : ""
              } ${selectedCourse === "All" ? "active" : ""}`}
              onClick={() => {
                setSelectedCourse("All");
                // isCourseSelected("All")
                //   ? handleAllClick({ target: { checked: false } })
                //   : handleAllClick({ target: { checked: true } });
              }}
            >
              <input
                type="checkbox"
                style={{ position: "inherit", margin: "0px" }}
                value="All"
                checked={isCourseSelected("All")}
                onChange={handleAllClick}
              />{" "}
              <p>All</p>
            </div>
          )}
          {CollegeStudentsFilters.find(
            (item) => item.domain === activeTab
          ).courseNames.map((cn, index) => {
            return (
              <div
                key={index}
                className={`stream-item ${
                  isCourseSelected(cn.courseName) ? "selected" : ""
                } ${selectedCourse === cn.courseName ? "active" : ""}`}
                onClick={() => {
                  setSelectedCourse(cn.courseName);
                  // isCourseSelected(courseName.courseName)
                  //   ? handleOptionClick({ target: { checked: false } }, courseName)
                  //   : handleOptionClick({ target: { checked: true } }, courseName);
                }}
              >
                <input
                  type="checkbox"
                  style={{ position: "inherit", margin: "0px" }}
                  value={cn.courseName}
                  checked={isCourseSelected(cn.courseName)}
                  onChange={(e) => handleOptionClick(e, cn)}
                />{" "}
                <p>{cn.courseName}</p>
              </div>
            );
          })}
        </div>

        <div className="stream-configs">
          {selectedCourse !== "All" && getAllProgrammes().length > 0 && (
            <>
              <p>Programmes</p>
              <div className="specialization-list">
                {getAllProgrammes().length > 1 && (
                  <div
                    className={`check-option ${
                      isProgrammeSelected("All") ? "selected" : ""
                    }`}
                    onClick={() => {
                      handleProgrammeSelect("All");
                    }}
                  >
                    All
                    {isProgrammeSelected("All") && (
                      <div className="green-tick">✓</div>
                    )}
                  </div>
                )}
                {getAllProgrammes().map((item, index) => {
                  const showSelected =
                    (getAllProgrammes().length > 1 &&
                      !isProgrammeSelected("All") &&
                      isProgrammeSelected(item)) ||
                    (getAllProgrammes().length === 1 &&
                      isProgrammeSelected(item));

                  return (
                    <div
                      key={index}
                      className={`check-option ${showSelected ? "selected" : ""}`}
                      onClick={() => {
                        handleProgrammeSelect(item);
                      }}
                    >
                      {item}
                      {showSelected && <div className="green-tick">✓</div>}
                    </div>
                  );
                })}
              </div>
              <br />
            </>
          )}

          {selectedCourse !== "All" && getAllCourseDurations().length > 0 && (
            <>
              <p>Course Duration</p>
              <div className="specialization-list">
                {getAllCourseDurations().length > 1 && (
                  <div
                    className={`check-option ${
                      isCourseDurationSelected("All") ? "selected" : ""
                    }`}
                    onClick={() => {
                      handleCourseDurationSelect("All");
                    }}
                  >
                    All
                    {isCourseDurationSelected("All") && (
                      <div className="green-tick">✓</div>
                    )}
                  </div>
                )}
                {getAllCourseDurations().map((item, index) => {
                  const showSelected =
                    (getAllCourseDurations().length > 1 &&
                      !isCourseDurationSelected("All") &&
                      isCourseDurationSelected(item)) ||
                    (getAllCourseDurations().length === 1 &&
                      isCourseDurationSelected(item));

                  return (
                    <div
                      key={index}
                      className={`check-option ${showSelected ? "selected" : ""}`}
                      onClick={() => {
                        handleCourseDurationSelect(item);
                      }}
                    >
                      {`${Math.ceil(item / 12)} Year${Math.ceil(item / 12) > 1 ? "s" : ""}`}
                      {showSelected && <div className="green-tick">✓</div>}
                    </div>
                  );
                })}
              </div>
              <br />
            </>
          )}

          {selectedCourse !== "All" && getAllYearOfStudies().length > 0 && (
            <>
              <p>Year of Study</p>
              <div className="specialization-list">
                {getAllYearOfStudies().length > 1 && (
                  <div
                    className={`check-option ${
                      isYearOfStudySelected("All") ? "selected" : ""
                    }`}
                    onClick={() => {
                      handleYearOfStudySelect("All");
                    }}
                  >
                    All
                    {isYearOfStudySelected("All") && (
                      <div className="green-tick">✓</div>
                    )}
                  </div>
                )}
                {getAllYearOfStudies().map((item, index) => {
                  const showSelected =
                    (getAllYearOfStudies().length > 1 &&
                      !isYearOfStudySelected("All") &&
                      isYearOfStudySelected(item)) ||
                    (getAllYearOfStudies().length === 1 &&
                      isYearOfStudySelected(item));

                  return (
                    <div
                      key={index}
                      className={`check-option ${showSelected ? "selected" : ""}`}
                      onClick={() => {
                        handleYearOfStudySelect(item);
                      }}
                    >
                      {getYearLabel(item)}
                      {showSelected && <div className="green-tick">✓</div>}
                    </div>
                  );
                })}
              </div>
              <br />
            </>
          )}

          {selectedCourse !== "All" && getAllPassoutYears().length > 0 && (
            <>
              <p>Graduating Years</p>
              <div className="specialization-list">
                {getAllPassoutYears().length > 1 && (
                  <div
                    className={`check-option ${
                      isPassoutYearSelected("All") ? "selected" : ""
                    }`}
                    onClick={() => {
                      handlePassoutYearSelect("All");
                    }}
                  >
                    All
                    {isPassoutYearSelected("All") && (
                      <div className="green-tick">✓</div>
                    )}
                  </div>
                )}
                {getAllPassoutYears().map((item, index) => {
                  const showSelected =
                    (getAllPassoutYears().length > 1 &&
                      !isPassoutYearSelected("All") &&
                      isPassoutYearSelected(item)) ||
                    (getAllPassoutYears().length === 1 &&
                      isPassoutYearSelected(item));

                  return (
                    <div
                      key={index}
                      className={`check-option ${showSelected ? "selected" : ""}`}
                      onClick={() => {
                        handlePassoutYearSelect(item);
                      }}
                    >
                      {item}
                      {showSelected && <div className="green-tick">✓</div>}
                    </div>
                  );
                })}
              </div>
              <br />
            </>
          )}

          {selectedCourse !== "All" && getAllSpecializations().length > 0 ? (
            <>
              <p>Specializations</p>
              <div className="specialization-list">
                <div
                  className={`check-option ${
                    isSpecializationSelected("All") ? "selected" : ""
                  }`}
                  onClick={() => {
                    handleSpecializationClick("All");
                  }}
                >
                  All
                  {isSpecializationSelected("All") && (
                    <div className="green-tick">✓</div>
                  )}
                </div>
                {getAllSpecializations().map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={`check-option ${!isSpecializationSelected("All") && isSpecializationSelected(item.specializationName) ? "selected" : ""}`}
                      onClick={() => {
                        handleSpecializationClick(item);
                      }}
                    >
                      {item.specializationName}
                      {!isSpecializationSelected("All") &&
                        isSpecializationSelected(item.specializationName) && (
                          <div className="green-tick">✓</div>
                        )}
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            selectedCourse !== "All" &&
            getAllSpecializations().length === 0 && (
              <div className="others-text">
                This option enables user to type in the course persuing as a
                text field
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
}

export default CollegeStudentsOptions;
