import React from "react";
import { IoMdInformationCircle } from "react-icons/io";
import "./Tooltip.css";

const Tooltip = ({ text, textSize = "14px", width = "260px" }) => {
  return (
    <div className="tooltip-container">
      <IoMdInformationCircle className="tooltip-icon" color="black" />
      <div
        className="tooltip-text"
        style={{ fontSize: textSize, width: width }}
      >
        {text}
      </div>
    </div>
  );
};

export default Tooltip;
