export function transformData(apiResponse) {
  const currentYear = new Date().getFullYear();

  // Group data by domain
  const groupedByDomain = apiResponse.reduce((acc, item) => {
    if (!acc[item.domain]) {
      acc[item.domain] = [];
    }
    acc[item.domain].push(item);
    return acc;
  }, {});

  // Transform each domain group
  const transformedData = Object.entries(groupedByDomain).map(
    ([domain, courses]) => {
      const courseMap = {};

      // Group courses by courseName and aggregate programmeName, specializationNames, courseDurations, and yearOfStudy
      courses.forEach((course) => {
        const {
          courseName,
          programmeName,
          courseDuration,
          specializationName,
        } = course;

        if (!courseMap[courseName]) {
          courseMap[courseName] = {
            courseName,
            programmeNames: [],
            specializationNames: [],
            passoutYears: [],
            courseDurations: [],
            yearOfStudy: [],
          };
        }

        // Add programmeName if not already present
        if (!courseMap[courseName].programmeNames.includes(programmeName)) {
          courseMap[courseName].programmeNames.push(programmeName);
        }

        // Add specializationName if not already present
        if (
          specializationName &&
          specializationName !== "Others" &&
          !courseMap[courseName].specializationNames.some(
            (spec) => spec.specializationName === specializationName
          )
        ) {
          courseMap[courseName].specializationNames.push({
            specializationName,
          });
        }

        // Add courseDuration if not already present
        if (!courseMap[courseName].courseDurations.includes(courseDuration)) {
          courseMap[courseName].courseDurations.push(courseDuration);
        }

        // Calculate all passout years from current year to the final year
        const endYear = currentYear + Math.floor(courseDuration / 12);
        const years = Array.from(
          { length: endYear - currentYear + 1 },
          (_, i) => currentYear + i
        );

        // Merge the calculated years into passoutYears
        courseMap[courseName].passoutYears = Array.from(
          new Set([...courseMap[courseName].passoutYears, ...years])
        ).sort((a, b) => a - b);
      });

      // Finalize course data with yearOfStudy
      const courseList = Object.values(courseMap).map((course) => {
        // Determine max duration and calculate yearOfStudy
        const maxDuration = Math.max(...course.courseDurations);
        const maxYears = Math.ceil(maxDuration / 12);
        return {
          ...course,
          yearOfStudy: Array.from({ length: maxYears }, (_, i) => i + 1),
          specializationNames: [
            ...course.specializationNames,
            { specializationName: "Others" },
            { specializationName: "NA" },
          ],
        };
      });

      // Add "Others" course and specialization
      courseList.push({
        courseName: "Others",
        programmeNames: [],
        specializationNames: [],
        passoutYears: [],
        courseDurations: [],
        yearOfStudy: [],
      });

      return {
        domain,
        courseNames: courseList,
      };
    }
  );

  // Add "Others" domain
  transformedData.push({
    domain: "Others",
    courseNames: [
      {
        courseName: "Others",
        programmeNames: [],
        specializationNames: [],
        passoutYears: [],
        courseDurations: [],
        yearOfStudy: [],
      },
    ],
  });

  return transformedData;
}
