import React, { useEffect, useState } from "react";
import { isEqual } from "../../../CommonFiles/Utils/ObjectUtils";

export const SchoolStudentsClasses = [
  "Class 1",
  "Class 2",
  "Class 3",
  "Class 4",
  "Class 5",
  "Class 6",
  "Class 7",
  "Class 8",
  "Class 9",
  "Class 10",
  "Class 11",
  "Class 12",
];

function SchoolStudentsOptions({
  schoolStudentsOptions,
  onSchoolStudentsOptionsChange,
}) {
  const [selectedOptions, setSelectedOptions] = useState(schoolStudentsOptions);

  useEffect(() => {
    if (!isEqual(selectedOptions, schoolStudentsOptions)) {
      onSchoolStudentsOptionsChange(selectedOptions);
    }
  }, [selectedOptions]);

  const handleOptionClick = (option) => {
    if (option === "All") {
      setSelectedOptions(SchoolStudentsClasses);
    } else {
      if (selectedOptions.includes(option)) {
        if (selectedOptions.length === 1) {
          setSelectedOptions(SchoolStudentsClasses);
        } else {
          if (selectedOptions.length === SchoolStudentsClasses.length) {
            setSelectedOptions([option]);
          } else {
            setSelectedOptions(
              selectedOptions.filter((item) => item !== option)
            );
          }
        }
      } else {
        setSelectedOptions([...selectedOptions, option]);
      }
    }
  };

  return (
    <div className="school-students-options-container">
      <div
        className={`check-option ${selectedOptions.length === SchoolStudentsClasses.length ? "selected" : ""}`}
        onClick={() => handleOptionClick("All")}
      >
        All
        {selectedOptions.length === SchoolStudentsClasses.length && (
          <div className="green-tick">✓</div>
        )}
      </div>
      {SchoolStudentsClasses.map((item, index) => {
        return (
          <div
            key={index}
            className={`check-option ${selectedOptions.length !== SchoolStudentsClasses.length && selectedOptions.includes(item) ? "selected" : ""}`}
            onClick={() => handleOptionClick(item)}
          >
            {item}
            {selectedOptions.length !== SchoolStudentsClasses.length &&
              selectedOptions.includes(item) && (
                <div className="green-tick">✓</div>
              )}
          </div>
        );
      })}
    </div>
  );
}

export default SchoolStudentsOptions;
