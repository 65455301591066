import React, { useEffect, useState } from "react";
import Modal from "../../CommonFiles/UI Elements/Modal/Modal";
import "./EligibilityConfigModal.css";
import CollegeStudentsOptions from "./EligibilityOptions/CollegeStudentsOptions";
import ProfessionalsOptions from "./EligibilityOptions/ProfessionalsOptions";
import SchoolStudentsOptions, {
  SchoolStudentsClasses,
} from "./EligibilityOptions/SchoolStudentsOptions";

const sidebarOptions = [
  { label: "Target Audience", icon: "fas fa-user", show: true },
  {
    label: "College Students",
    icon: "fas fa-graduation-cap",
    show: false,
    options: "CollegeStudentsOptions",
  },
  {
    label: "Professionals",
    icon: "fas fa-briefcase",
    show: false,
    options: "ProfessionalsOptions",
  },
  {
    label: "School Students",
    icon: "fas fa-book",
    show: false,
    options: "SchoolStudentsOptions",
  },
];

function EligibilityConfigModal({
  isOpen,
  onClose,
  onConfigSave,
  CollegeStudentsFilters,
  existingConfig,
}) {
  const [InitialConfig, setInitialConfig] = useState({
    CollegeStudentsOptions: CollegeStudentsFilters,
    ProfessionalsOptions: {
      isOpenToAll: true,
      experienceMin: 0,
      experienceMax: 0,
    },
    SchoolStudentsOptions: SchoolStudentsClasses,
  });

  const [selectedOption, setSelectedOption] = useState("Target Audience");
  const [isSelectedAll, setIsSelectedAll] = useState(
    existingConfig ? false : true
  );
  const [selectedFilters, setSelectedFilters] = useState(
    existingConfig ?? InitialConfig
  );
  const [errors, setErrors] = useState([]);
  const [currentSidebarOptions, setCurrentSidebarOptions] =
    useState(sidebarOptions);

  useEffect(() => {
    if (
      !existingConfig &&
      currentSidebarOptions.filter((item) => item.show).length === 1
    ) {
      setIsSelectedAll(true);
      setSelectedFilters(InitialConfig);
    }
    // eslint-disable-next-line
  }, [currentSidebarOptions]);

  useEffect(() => {
    if (existingConfig) {
      setIsSelectedAll(false);
      setSelectedFilters(existingConfig);
      setCurrentSidebarOptions(
        sidebarOptions.map((item) => ({
          ...item,
          show:
            item.label === "Target Audience" ||
            Object.keys(existingConfig).includes(item.options)
              ? true
              : false,
        }))
      );
    }
  }, [existingConfig]);

  useEffect(() => {
    if (CollegeStudentsFilters.length > 0) {
      const InitConfig = {
        CollegeStudentsOptions: CollegeStudentsFilters,
        ProfessionalsOptions: {
          isOpenToAll: true,
          experienceMin: 0,
          experienceMax: 0,
        },
        SchoolStudentsOptions: SchoolStudentsClasses,
      };
      setInitialConfig(InitConfig);
      setSelectedFilters(existingConfig ?? InitConfig);
    }
    // eslint-disable-next-line
  }, [CollegeStudentsFilters]);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const handleTargetAudienceClick = (option) => {
    if (option === "All") {
      setSelectedFilters(InitialConfig);
      setIsSelectedAll(true);
      setCurrentSidebarOptions(
        sidebarOptions.map((item) => ({
          ...item,
          show: item.label === "Target Audience" ? true : false,
        }))
      );
      return;
    }

    setIsSelectedAll(false);
    const updatedSidebarOptions = [...currentSidebarOptions];
    const updatedItemIndex = updatedSidebarOptions.findIndex(
      (item) => item.label === option
    );

    if (updatedItemIndex !== -1) {
      updatedSidebarOptions[updatedItemIndex].show =
        !updatedSidebarOptions[updatedItemIndex].show;

      if (updatedSidebarOptions[updatedItemIndex].show) {
        if (selectedFilters === InitialConfig) {
          setSelectedFilters({
            [updatedSidebarOptions[updatedItemIndex].options]:
              InitialConfig[updatedSidebarOptions[updatedItemIndex].options],
          });
        } else {
          setSelectedFilters({
            ...selectedFilters,
            [updatedSidebarOptions[updatedItemIndex].options]:
              InitialConfig[updatedSidebarOptions[updatedItemIndex].options],
          });
        }
      } else {
        const updatedFilters = { ...selectedFilters };
        delete updatedFilters[updatedSidebarOptions[updatedItemIndex].options];
        setSelectedFilters(updatedFilters);
      }
    }
    setCurrentSidebarOptions(updatedSidebarOptions);
  };

  const handleErrors = (op, tab) => {
    if (op === "Add") {
      !errors.includes(tab) && setErrors([...errors, tab]);
    } else if (op === "Remove") {
      const updatedErrors = [...errors];
      const index = updatedErrors.indexOf(tab);
      if (index !== -1) {
        updatedErrors.splice(index, 1);
      }
      setErrors(updatedErrors);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={"Configure Eligibility"}
      width="1200px"
    >
      <div className="eligibility-container">
        <div className="sidebar">
          {currentSidebarOptions.map((option, index) => {
            if (option.show) {
              return (
                <div
                  key={index}
                  className={`sidebar-item ${selectedOption === option.label ? "active" : ""}`}
                  onClick={() => handleOptionClick(option.label)}
                >
                  <div className="icon-container">
                    <i className={option.icon}></i>
                  </div>
                  <span>{option.label}</span>
                </div>
              );
            }
            return null;
          })}
        </div>
        <div className="main-content">
          {selectedOption === "Target Audience" && (
            <div className="target-audience">
              <div
                className={`option ${isSelectedAll ? "active" : ""}`}
                onClick={() => handleTargetAudienceClick("All")}
              >
                <div className="icon-container">
                  <i className="fas fa-users"></i>
                </div>
                <span>Everyone</span>
              </div>
              <div
                className={`option ${currentSidebarOptions[1].show ? "active" : ""}`}
                onClick={() => handleTargetAudienceClick("College Students")}
              >
                <div className="icon-container">
                  <i className="fas fa-graduation-cap"></i>
                </div>
                <span>College Students</span>
              </div>
              <div
                className={`option ${currentSidebarOptions[2].show ? "active" : ""}`}
                onClick={() => handleTargetAudienceClick("Professionals")}
              >
                <div className="icon-container">
                  <i className="fas fa-briefcase"></i>
                </div>
                <span>Professionals</span>
              </div>
              <div
                className={`option ${currentSidebarOptions[3].show ? "active" : ""}`}
                onClick={() => handleTargetAudienceClick("School Students")}
              >
                <div className="icon-container">
                  <i className="fas fa-book"></i>
                </div>
                <span>School Students</span>
              </div>
            </div>
          )}

          {selectedOption === "College Students" && (
            <CollegeStudentsOptions
              collegeStudentsOptions={selectedFilters.CollegeStudentsOptions}
              onCollegeStudentsOptionsChange={(newValue) =>
                setSelectedFilters({
                  ...selectedFilters,
                  CollegeStudentsOptions: newValue,
                })
              }
              CollegeStudentsFilters={CollegeStudentsFilters}
            />
          )}

          {selectedOption === "Professionals" && (
            <ProfessionalsOptions
              error={handleErrors}
              professionalOptions={selectedFilters.ProfessionalsOptions}
              onProfessionalOptionsChange={(newValue) =>
                setSelectedFilters({
                  ...selectedFilters,
                  ProfessionalsOptions: newValue,
                })
              }
            />
          )}

          {selectedOption === "School Students" && (
            <SchoolStudentsOptions
              schoolStudentsOptions={selectedFilters.SchoolStudentsOptions}
              onSchoolStudentsOptionsChange={(newValue) =>
                setSelectedFilters({
                  ...selectedFilters,
                  SchoolStudentsOptions: newValue,
                })
              }
            />
          )}
        </div>
      </div>
      <div className="eligibility-modal-footer">
        <button className="cancel-btn" onClick={onClose}>
          Close
        </button>
        <button
          className="save-btn"
          disabled={errors.length > 0}
          onClick={() => onConfigSave(selectedFilters)}
        >
          Save
        </button>
      </div>
    </Modal>
  );
}

export default EligibilityConfigModal;
